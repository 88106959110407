export const PAGE = {
  Product: 1,
  Cart: 2,
  OrderStatus: 3
};

export const BOX_TYPE = {
  Default: 'default',
  ImageTop: 'ImgTop',
  ImageLeft: 'ImgLeft'
};

export const LAYOUT_TYPE = {
  Default: 'default',
  Flex: 'flex',
  Slider: 'slider'
};

export const ADDON_TYPE = {
  Button: 'button',
  Checkbox: 'checkbox',
  QtyBox: 'qtyBox',
  Dropdown: 'dropdown',
  ButtonQtyBox: 'buttonQtyBox'
};
