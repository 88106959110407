const Element_Ids = {
  price: 'upsell-price',
  priceTargetElement: 'upsell-price-target',
  varaintSelector: 'upsell-variant-selector-',
  popUpVariantSelector: 'upsell-popup-variant-selector-',
  bundleVariantSelector: 'upsell-bundle-variant-selector-',
  addOnItemContainer: 'upsell-addon-',
  uiBlockWrapper: 'upsell-ui-block-wrapper',
  descBlockContainer: 'upsell-desc-block-special-'
};

export default Element_Ids;
