import {
  getAppProps,
  getAvailableWithTitle,
  getOtherAddonDetails,
  getPreparedAddonRecordItems,
  getPriceDetails,
  safeEncodeURIComponent
} from '../../../utils';
import { TRANSLATE_IDS, translateText } from '../../../utils/translate';
import CssClasses from '../../common/cssClasses';
import Element_Ids from '../../common/elementIds';
import { upsellCheckboxPrefix, upsellInputPrefix } from './data';
import { getItemDiscountById, getItemPropertyById } from '../../../utils/item';

export function variantPopUpDropdown(
  productHandle,
  variantOptions,
  label,
  data
) {
  if (variantOptions && variantOptions.length > 0) {
    let options = '';
    const optionsLabel = label ? `${label}:` : '';
    for (let i = 0; i < variantOptions.length; i++) {
      options += `<option value=${variantOptions[i].id}>${variantOptions[i].title}</option>`;
    }
    return `<div class="${CssClasses.variantDropdown}">
                <label>${optionsLabel}</label>
                <select class="${CssClasses.popUpVariantSelector}" id="${
      Element_Ids.popUpVariantSelector
    }${data.id}" 
                        onchange="upsell.changePopupVariant('${productHandle}', 
                                                       '${safeEncodeURIComponent(
                                                         JSON.stringify(
                                                           variantOptions
                                                         )
                                                       )}',
                                                       this.value, '${safeEncodeURIComponent(
                                                         JSON.stringify(data)
                                                       )}')">
                        ${options}
                </select>
            </div>`;
  }
  return '';
}

export function popupVariantSelectionHandler() {
  window.upsell = window.upsell || {};
  window.upsell.changePopupVariant = function(
    productHandle,
    variantOptionsJson,
    selectedVariantId,
    dataJson
  ) {
    // eslint-disable-next-line no-unused-vars
    const data = JSON.parse(decodeURIComponent(dataJson));
    const { options, popups } = getAppProps();

    const variantOptions = JSON.parse(decodeURIComponent(variantOptionsJson));
    const variantOption = variantOptions.find(function(variantOption) {
      return variantOption.id === parseInt(selectedVariantId);
    });

    // Root
    const id = variantOptions[0].id; // Use first variant id, we can't use
    const addOnContainer = document.querySelector(`#upsell-product-${id}`);

    // Url
    const aTags = addOnContainer.querySelectorAll('a');
    for (let i = 0; i < aTags.length; i++) {
      aTags[i].setAttribute('href', variantOption.url);
    }

    // Image
    const imgSrc = options.useThumbnailImage
      ? variantOption.originalSmallImage
      : variantOption.image;
    addOnContainer.querySelector('img').setAttribute('src', imgSrc || '');

    // Price
    const priceDetails = getPriceDetails(
      options,
      variantOption.price,
      variantOption.compareAtPrice
    );

    const { popupItems: records } = getPreparedAddonRecordItems();

    const optionId = variantOption.id;
    const addOnRecord = records.find(v => v.ids.includes(optionId)) || {
      discount: 0
    };
    const item = {
      variant_id: optionId
    };
    // Override discount because it grouped by default.
    addOnRecord.discount = getItemDiscountById(popups, item);

    const {
      compareAtPrice,
      finalPrice,
      save,
      finalPriceNumber,
      priceNumber
    } = getOtherAddonDetails({
      priceDetails,
      addOnRecord
    });

    // Update price element
    addOnContainer.querySelector(
      '.upsell-product-price'
    ).innerHTML = finalPrice;

    let originalPriceVisible = true;
    if (!options.showCompareAtPrice || finalPrice === compareAtPrice) {
      originalPriceVisible = false;
    }

    // Discount applied
    if (priceNumber !== finalPriceNumber) {
      originalPriceVisible = true;
    }

    if (originalPriceVisible) {
      addOnContainer.querySelector(
        '.upsell-product-original-price'
      ).innerHTML = compareAtPrice;
    } else {
      addOnContainer.querySelector('.upsell-product-original-price').innerHTML =
        '';
    }

    if (originalPriceVisible) {
      const saveText = translateText(TRANSLATE_IDS.popUpSaveText);
      addOnContainer.querySelector(
        '.upsell-product-savings'
      ).innerHTML = `${saveText} ${save}`;
    } else {
      const savings = addOnContainer.querySelector('.upsell-product-savings');
      if (savings) {
        savings.innerHTML = '';
      }
    }

    const availableWithElement = addOnContainer.querySelector(
      '.upsell-product-available-with'
    );
    if (availableWithElement) {
      const state = getItemPropertyById(popups, item, 'availableWith', false);
      availableWithElement.innerHTML = state ? getAvailableWithTitle() : '';
    }

    // reset all
    resetVariants(data, variantOptions);
    resetCheckbox(data);
    resetQty(data);
  };
}

function resetVariants(data, variantOptions) {
  // Reset
  window.upsellSetPopUpItemState(data.id, true);

  // delete
  const ids = variantOptions.map(function(variantOption) {
    return variantOption.id;
  });
  ids.forEach(v => {
    window.upsellRemovePopUpStoreAddon(v);
  });
}

function resetCheckbox(data) {
  const el = jUpsell(`#${upsellCheckboxPrefix}${data.id}`);
  el.prop('checked', false);
}

function resetQty(data) {
  const el = jUpsell(`#${upsellInputPrefix}${data.id}`);
  el.val('1');
}
