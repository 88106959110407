const STYLE = `
.image-upsell-snackbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  -webkit-animation: upsell-snackbar-bringdown 1s none;
  animation: upsell-snackbar-bringdown 1s none;
}

 @-webkit-keyframes upsell-snackbar-bringdown {
    0% {
      -webkit-transform: translateY(-200px);
      transform: translateY(-200px);
    }
  }

  @keyframes upsell-snackbar-bringdown {
    0% {
      -webkit-transform: translateY(-200px);
      transform: translateY(-200px);
    }
  }

.image-upsell-snackbar-contain {
    background: #fff;
    color: black;
    min-height: 55px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 10px 15px 10px 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
  }
  
  .image-upsell-snackbar-contain span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: flex-start;
          -ms-flex-align: flex-start;
              align-items: flex-start;
      font-size: 14px;
      height: 100%;
    }
  
.image-upsell-snakbar-contain-flex {
    display: flex;
    justify-content: center;
    align-items: baseline;
}
`;

const REPLACE_ID = '$TEXT$';
const HTML = `
<div class="error-message-wrapper">
   <div class="image-upsell-snackbar">
      <div class="image-upsell-snackbar-contain">
        <span>
            <img src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfNSIgZGF0YS1uYW1lPSJMYXllciA1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOTAgMjkwIj4KPHRpdGxlPkVycm9yPC90aXRsZT4KPGNpcmNsZSBjeD0iMTQ1IiBjeT0iMTQ1IiByPSIxNDUiIHN0eWxlPSJmaWxsOnJlZCIvPgo8cGF0aCBkPSJNMjI4Ljc3LDE1Ni41MnEwLTUuMjcsNS44OC01LjI3aDMxLjE4cTUuMjgsMCw1LjI4LDUuMjdWMjg1LjhhNS40OSw1LjQ5LDAsMCwxLTEuMzIsMy43Nyw1LDUsMCwwLDEtNCwxLjVIMjM0LjY1YTYuNzYsNi43NiwwLDAsMS0zLjg0LTEuNDQsNC40Niw0LjQ2LDAsMCwxLTItMy44M1ptMCwxNTcuN2E1LjMyLDUuMzIsMCwwLDEsMS4zOC0zLjg0LDUuMTUsNS4xNSwwLDAsMSwzLjktMS40NGgzMS42NmE1LjgxLDUuODEsMCwwLDEsMy43OCwxLjQ0LDQuNzYsNC43NiwwLDAsMSwxLjc0LDMuODR2MjlhNS42OSw1LjY5LDAsMCwxLTEuNDQsMy44Myw1LjA4LDUuMDgsMCwwLDEtNC4wOCwxLjY4SDIzNC4wNXEtNS4yOCwwLTUuMjgtNS41MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDUgLTEwNSkiIHN0eWxlPSJmaWxsOiNmZmYiLz4KPC9zdmc+" alt="Error " style="width: 20px; margin: 2px 10px;">
            <span>${REPLACE_ID}</span>
        </span>
      </div>
   </div>
</div>
`;

const DELAY = 6000;
const CONTAINER_ID = 'upsell-snackbar-main-container';

function createStyles() {
  if (document.getElementById('upsell-snackbar-styles')) {
    return;
  }

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = STYLE;
  style.id = 'upsell-snackbar-styles';
  document.getElementsByTagName('head')[0].appendChild(style);
}

function createHTML(msg) {
  const div = document.createElement('div');
  div.innerHTML = HTML.replace(REPLACE_ID, msg);
  div.id = CONTAINER_ID;
  document.getElementsByTagName('body')[0].appendChild(div);
}

function removeHTML() {
  const div = document.getElementById(CONTAINER_ID);
  if (div) {
    div.remove();
  }
}

function createTip(msg) {
  removeHTML();

  createStyles();
  createHTML(msg);

  window.setTimeout(() => {
    removeHTML();
  }, DELAY);
}

export function showWarningTip(msg) {
  createTip(msg);
}
