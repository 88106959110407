import {
  getAddOnProduct,
  getAppProps,
  getDefaultCurrencyFormatSymbolName,
  getMainProductQuantity,
  hex2a,
  isProductPage
} from './utils';
import { getDiscountOfferProduct, RENDER_TYPES } from './common/discountOffer';
import { prepareAddonDetails } from './getAddOnDetails';
const TRACKING_CODE = GA_TRACKING;
let trackerRequired = false;

const EVENT_NAMES = {
  AddToCart: 'add_to_cart',
  RemoveFromCart: 'remove_from_cart',
};

export const ANALYTIC_CATEGORIES = {
  Product: 'Upsell Product Page',
  Cart: 'Upsell Cart Page',
  Popup: 'Upsell Popup',
  DiscountOffer: 'Upsell Bundles',
  AddToCart: 'Add To Cart'
};

function sendVariablesData(addOnIds, eventCategory, isRemove) {
  const currency = getDefaultCurrencyFormatSymbolName();

  for (let addOnId in addOnIds) {
    // Get product from addon or from popups (if we dont' have)
    const addOnProduct =
      getAddOnProduct(addOnId, 'addons') ||
      getAddOnProduct(addOnId, 'popups') ||
      getDiscountOfferProduct(addOnId, RENDER_TYPES.All) ||
      getDiscountOfferProduct(addOnId, RENDER_TYPES.Collection) ||
      getDiscountOfferProduct(addOnId, RENDER_TYPES.Catalog);

    if (addOnProduct) {
      const productList =
        window.upsell.product &&
        window.upsell.product.details &&
        window.upsell.product.details.title;

      gtagSendEvent(isRemove ? EVENT_NAMES.RemoveFromCart : EVENT_NAMES.AddToCart, {
        custom_category: eventCategory,
        custom_label:
          window.upsell.product && window.upsell.product.details
            ? window.upsell.product.details.title.toString()
            : 'cart',
        list_name: productList ? productList : 'cart',
        currency,
        value: addOnIds[addOnId] * addOnProduct.price,
        items: [
          {
            item_id: addOnId,
            item_name: addOnProduct.title,
            price: addOnProduct.price,
            quantity: addOnIds[addOnId],
            custom_product_variant: addOnProduct.variant
          }
        ]
      });
    }
  }
}

function setupTrackerAnalytics() {
  if (!trackerRequired) {
    window.dataLayer = window.dataLayer || [];

    if (!window.gtag) {
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
    }

    // Apply our code
    window.gtag('config', getGtagCode());
    trackerRequired = true;
  }
}

function isAnalyticsEnabled() {
  const { options } = getAppProps();

  // Skip analytics sending
  if (options.disableGaAnalytics) {
    return false;
  }

  return true;
}

export default function analytics(addOnIds, eventCategory, isRemove) {
  if (!isAnalyticsEnabled()) {
    return;
  }

  sendVariablesData(addOnIds, eventCategory, isRemove);
}

export function sendAddToCartAnalytics() {
  if (!isProductPage()) {
    return;
  }

  if (!isAnalyticsEnabled()) {
    return;
  }

  const eventCategory = ANALYTIC_CATEGORIES.AddToCart;

  const { options } = getAppProps();

  const url = new URL(document.URL);
  const variantIdStr = url.searchParams.get('variant');
  let variantId =
    typeof variantIdStr === 'string'
      ? parseInt(variantIdStr)
      : // eslint-disable-next-line no-undef
        upsell.product.details.variants[0].id;

  let variant = '';
  if (variantIdStr) {
    const variantInfo = window.upsell.product.details.variants.find(
      v => v.id === parseInt(variantIdStr)
    );
    if (variantInfo) {
      variant = variantInfo.title;
    }
  }

  const product = prepareAddonDetails({
    options,
    result: {
      // eslint-disable-next-line no-undef
      product: upsell.product.details
    },
    addOnRecord: {
      ids: [variantId]
    }
  });

  const quantity = getMainProductQuantity();
  const currency = getDefaultCurrencyFormatSymbolName();

  // Send all data
  gtagSendEvent(EVENT_NAMES.AddToCart, {
    custom_category: eventCategory,
    custom_label: product.title,
    currency,
    value: product.price * quantity,
    items: [
      {
        item_id: product.id,
        item_name: product.title,
        price: product.price,
        quantity,
        custom_product_variant: variant
      }
    ]
  });
}

/* gtag functions */
function getGtagCode() {
  return hex2a(TRACKING_CODE);
}

function gtagSendEvent(name, args) {
  // try setup
  if (!window.gtag) {
    setupTrackerAnalytics();
  }

  if (!window.gtag) {
    return;
  }

  const code = getGtagCode();

  window.gtag('event', name, {
    send_to: code,
    custom_data_source: Shopify.shop,
    custom_action: name,
    eventCategory: args.custom_category,
    eventLabel: args.custom_label,
    ...args
  });
}
