import { getShopName } from '../utils/shopify';
import { getProductJsonUrl, isProductPage } from '../utils';
import { removeUpsell } from '../render/common/utils';
import errorHandler from '../errorHandler';
import { getCollectionIds } from '../collectionAddOns';
import { PAGE } from '../domain';

let CACHED_DETAILS_PRODUCT_INFO = null;

function getFirebase() {
  const config = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    databaseURL: DATABASE_URL,
    projectId: PROJECT_ID
  };

  return firebase.initializeApp(config, 'addOnApp');
}

async function fetchField(firebase, postfix) {
  return new Promise(resolve => {
    const shopname = getShopName();

    const root = firebase.database().ref(shopname + '/' + postfix);

    root.once('value', function(snapshot) {
      const val = snapshot.val();
      resolve({ key: postfix, val });
    });
  });
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

function createAssociativeArray(string, object) {
  var parts = string.split('/');
  var last = parts[parts.length - 1];
  var tree = {};

  var node = parts.slice(0, -1).reduce(function(memo, current) {
    return (memo[current] = {});
  }, tree);

  node[last] = object;
  return tree;
}

function composeSnapshots(val) {
  let obj = {};

  val.forEach(v => {
    const key = v.key;
    const val = v.val;
    if (val) {
      obj = mergeDeep(obj, createAssociativeArray(key, val));
    }
  });

  return obj;
}

const ULTIMATE_FIELDS = [
  'addons',
  'popups',
  'discount_offer',
  'discount_offer_collection',
  'discount_offer_catalog'
];

async function fetchFirebaseFields(callback) {
  const firebase = getFirebase();

  // get settings
  const data = await fetchField(firebase, 'prefs');
  if (data && !data.val){
    data.val = {};
  }
  const val = data.val;
  const promises = [];
  const keys = val.ultimate ? ULTIMATE_FIELDS : ['addons'];

  if (val.caching){
    // load all: addons, popups
    keys.forEach(v => {
      promises.push(fetchField(firebase, v));
    });
  } else {
    // load specific data
    keys.forEach(v => {
      if (v.includes('discount_offer')){
        // load product
        if (v === 'discount_offer'){
          // specific
          const res = window.upsell.product.details;
          let result = {
            product: res
          };
          const availableVariants = result.product.variants.filter(i => i.available);
          const id = result.product.id;

          // current
          promises.push(fetchField(firebase, v + '/' + id));

          // all variant for product
          availableVariants.forEach(iter => {
            promises.push(fetchField(firebase, v + '/' + id + '|' + iter.id));
          });
        }

        // load collections
        if (v === 'discount_offer_collection'){
          const collectionAddOns = getCollectionIds(
            PAGE.Product,
            window.upsell.product.details.id
          );
          if (collectionAddOns){
            collectionAddOns.forEach(collectionId => {
              promises.push(fetchField(firebase, v + `/${collectionId}`));
            });
          }
        }

        // load catalog
        if (v === 'discount_offer_catalog'){
          promises.push(fetchField(firebase, v + '/all'));
        }
      } else {
        // all
        promises.push(fetchField(firebase, v + '/all'));

        // collection
        const collectionAddOns = getCollectionIds(
          PAGE.Product,
          window.upsell.product.details.id
        );
        if (collectionAddOns){
          collectionAddOns.forEach(collectionId => {
            promises.push(fetchField(firebase, v + `/collection/${collectionId}`));
          });
        }

        // specific
        const res = window.upsell.product.details;
        let result = {
          product: res
        };
        const availableVariants = result.product.variants.filter(i => i.available);
        const id = result.product.id;

        // current
        promises.push(fetchField(firebase, v + '/' + id));

        // all variant for product
        availableVariants.forEach(iter => {
          promises.push(fetchField(firebase, v + '/' + id + '|' + iter.id));
        });
      }
    });
  }

  // eslint-disable-next-line no-undef
  Promise.all(promises).then(val => {
    // add options
    const values = val;
    values.push(data);

    // combine all
    const snapshots = composeSnapshots(values);
    callback(snapshots);
  });
}

function fetchProductDetailsInfo(callback) {
  if (CACHED_DETAILS_PRODUCT_INFO) {
    callback(CACHED_DETAILS_PRODUCT_INFO);
    return;
  }

  const productDetailsUrl = IS_SHOPIFY_ENV
    ? getProductJsonUrl()
    : '/data/product.json';

  jUpsell
    .getJSON(productDetailsUrl, function(res) {
      callback(res);

      CACHED_DETAILS_PRODUCT_INFO = res;
    })
    .fail(function(response) {
      removeUpsell();
      errorHandler(
        new Error(
          `Errors in fetching product details ${productDetailsUrl}. StatusCode:${response.status}`
        ),
        true
      );
    });
}

export function fetchProduct(callback) {
  try {
    fetchProductDetailsInfo(function(res) {
      try {
        let result = {
          product: res
        };
        if (result && result.product) {
          window.upsell = window.upsell || {};
          window.upsell.product = window.upsell.product || {};
          window.upsell.product.details = result.product;
          callback();
        }
      } catch (err) {
        removeUpsell();
        errorHandler(err);
      }
    });
  } catch (err) {
    removeUpsell();
    errorHandler(err);
  }
}

export default function queryDatabase(callback) {
  if (isProductPage()) {
    fetchProduct(() =>
      // Fetch prefs and specific nodes
      fetchFirebaseFields(callback)
    );
  } else {
    const shopname = getShopName();

    const root = getFirebase()
      .database()
      .ref(shopname)
      .limitToFirst(6);

    root.once('value', function(snapshot) {
      const rootVal = snapshot.val();
      callback(rootVal);
    });
  }
}
