import {
  isCartPage,
  isRenderCartAddons,
  isThemeEditor,
  isUltimateSubscription
} from '../../utils';
import { APP_BLOCK_CONTAINER } from './utils';
import { DISCOUNT_OFFER_BLOCK_ID } from '../../template/components/discountOffer';

const ID_REPLACE = 'ID_TEXT_REPLACE';
const ID_DISCOUNT_OFFER_REPLACE = 'ID_DISCOUNT_OFFER_REPLACE';

const texts = {
  addons: {
    default: 'Upsell add-ons will be here',
    ultimate: 'Ultimate feature only. Upgrade Now.'
  },
  discountOffer: {
    default: 'Discounted product bundle will be here',
    ultimate: 'Ultimate feature only. Upgrade Now.'
  }
};

const css = `
    <style>
        .${DISCOUNT_OFFER_BLOCK_ID}:empty,
        .${APP_BLOCK_CONTAINER}:empty {
            display: block !important; /* Show in all themes */
            background: aliceblue;
            padding: 40px;
            text-align: center;
        }
        
        .${APP_BLOCK_CONTAINER}:empty:after {
            content: '${ID_REPLACE}';
        }
        
        .${DISCOUNT_OFFER_BLOCK_ID}:empty:after {
            content: '${ID_DISCOUNT_OFFER_REPLACE}';
        }
    </style>
  `;

export default function injectThemeEditorStyles() {
  if (!isThemeEditor()) {
    return;
  }

  const cartVisible = isRenderCartAddons();
  const discountOfferVisible = isUltimateSubscription();

  let styles = css;

  if (isCartPage()) {
    styles = styles.replace(
      ID_REPLACE,
      cartVisible ? texts.addons.default : texts.addons.ultimate
    );
  } else {
    styles = styles
      .replace(ID_REPLACE, texts.addons.default)
      .replace(
        ID_DISCOUNT_OFFER_REPLACE,
        discountOfferVisible
          ? texts.discountOffer.default
          : texts.discountOffer.ultimate
      );
  }

  document.querySelector('head').insertAdjacentHTML('beforeend', styles);
}
