import showError from '../../../utils/error';
import { getAppProps, isCartPage, isProductPage } from '../../../utils';
import showInfo from '../../../utils/info';
import { getCartDrawersForm } from '../../../render/common/utils';
import { getSelectedPopUpAddons } from './common';

class Wrapper {
  constructor(args = {}) {
    const { options } = getAppProps();
    this.options = options;
    this.useCheckout = args.useCheckout || false;
    this.useContinue = args.useContinue || false;
  }

  _addItemToCartFinal(options) {
    let { mainProduct, items, outsideClick } = options;

    const ignoreAddToCart =
      options.ignoreAddToCart || (this.isUseContinue() && outsideClick);
    const self = this;

    // Main product
    const currentSlectedItems = [...items];

    // Our addons selected
    const popupSelectedItems = getSelectedPopUpAddons();
    // eslint-disable-next-line no-unused-vars
    for (const [id, item] of Object.entries(popupSelectedItems)) {
      currentSlectedItems.push(item);
    }

    if (
      (mainProduct && items.length === 0 && currentSlectedItems.length === 0) ||
      (items.length === 0 && currentSlectedItems.length > 0)
    ) {
      if (isCartPage()) {
        showInfo('Added to cart', self.options);
      }
      if (!ignoreAddToCart) {
        self.onAddToCart(currentSlectedItems);
      }
      return;
    }

    // eslint-disable-next-line no-undef
    fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        if (json.status && json.status !== 200) {
          showError(json, self.options, json.description);
          return;
        }

        if (isCartPage()) {
          showInfo('Added to cart', self.options);
        }
        if (!ignoreAddToCart) {
          self.onAddToCart(currentSlectedItems);
        }

        // Use refresh page for some clients
        if (isProductPage()) {
          let reload = false;

          if (getCartDrawersForm()) {
            reload = true;
          }

          if (self.options.overridePopUpRefresh) {
            reload = true;
          }

          if (reload) {
            // eslint-disable-next-line no-undef
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      })
      .catch(function(error) {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  onAddToCart() {}

  onEmpty() {
    // Called if items is not selected (implemented in cart hook)
  }

  isUseCheckout() {
    return this.useCheckout;
  }

  isUseContinue() {
    return this.useContinue;
  }
}

export default Wrapper;
