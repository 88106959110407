const upsellInputPrefix = 'upsell-addon-input-popup-custom-';
const upsellButtonPrefix = 'upsell-addon-button-popup-custom-';
const upsellCheckboxPrefix = 'upsell-addon-checkbox-popup-custom-';
const popUpId = 'pop-up-window';
const modalId = 'un-modal-content';

export function getActualAddons() {
  return window.upsell.popups || [];
}

export {
  popUpId,
  modalId,
  upsellInputPrefix,
  upsellButtonPrefix,
  upsellCheckboxPrefix
};
