import { LAYOUT_TYPE, PAGE } from '../../domain';
import {
  cartPageContainer,
  productPageContainer,
  TOKENS
} from '../../templates';
import buildContainer from '../build/buildContainer';
import {
  getForm,
  getSubmitButton,
  removeUpsell,
  tryRenderInKnownContainer
} from '../common/utils';
import { getAppProps } from '../../utils';
import placeholder from '../../template/components/placeholder';

function renderPlaceholder(page, productHandle) {
  const { options } = getAppProps();

  removeUpsell(); // If fast change variants fix

  const containerTemplate =
    page === PAGE.Product
      ? productPageContainer(
          options.productLayout || LAYOUT_TYPE.Default,
          productHandle
        )
      : cartPageContainer(options.cartLayout || LAYOUT_TYPE.Default);
  const containerHtml = buildContainer(containerTemplate, options);
  const loading = containerHtml.replace(TOKENS.addOns, placeholder());

  if (tryRenderInKnownContainer(loading)) return;

  const cartForm = getForm(page, options);

  if (!cartForm) return;

  if (page === PAGE.Product) {
    if (options.position === 'end') {
      cartForm.insertAdjacentHTML('afterend', loading);
    } else if (options.position === 'before') {
      cartForm.insertAdjacentHTML('beforebegin', loading);
    } else {
      const addToCartButton = getSubmitButton(options, cartForm, page);

      if (!addToCartButton) return;

      if (options.position === 'after') {
        addToCartButton.insertAdjacentHTML('afterend', loading);
      } else {
        addToCartButton.insertAdjacentHTML('beforebegin', loading);
      }
    }
  } else if (page === PAGE.Cart) {
    if (options.cartPageFormInjectorSelector) {
      const element = document.querySelector(
        options.cartPageFormInjectorSelector
      );
      if (element) {
        element.insertAdjacentHTML('afterend', loading);
      }
      return;
    }

    if (options.cart === 'bottom') {
      cartForm.insertAdjacentHTML('afterend', loading);
      return;
    }

    let cartElement = cartForm;
    if (options.cart === 'submit') {
      const checkoutButton = getSubmitButton(options, cartForm);
      if (checkoutButton) {
        cartElement = checkoutButton;
      }
    }

    cartElement.insertAdjacentHTML('beforebegin', loading);
  } else if (page === PAGE.OrderStatus) {
    const checkoutButton = getSubmitButton(options, cartForm, PAGE.OrderStatus);
    if (checkoutButton) {
      checkoutButton.insertAdjacentHTML('beforebegin', loading);
    }
  }
}

export default renderPlaceholder;
