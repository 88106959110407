import { getAppProps, isNoProduct } from '../utils';
import {
  addOnAddToCartActionHander,
  trackItemsInCart
} from '../cartActionHandler';
import { getAddOns, sortAddOns, wrapAddOns } from '../addOnsUtil';
import getCollectionAddOns from '../collectionAddOns';
import { PAGE } from '../domain';
import getCatalogAddOns from '../catalogAddOns';
import renderer, { removeUpsell } from '../renderer';
import errorHandler from '../errorHandler';
import { setPopUpData } from './productPageInjector/utils';
import renderDiscountOffer from '../template/components/discountOffer';
import injectStyles from '../render/common/injectStyles';

const PrepareType = {
  Addons: 'addons',
  Popups: 'popups'
};

function prepareAddons({
  addOnsDb,
  id,
  selectedVariantId,
  variantToIgnore,
  groupVairantsTracker,
  options,
  type
}) {
  const addOnsTracker = {};
  const addOns = getAddOns(
    function() {
      return wrapAddOns(addOnsDb, id, selectedVariantId);
    },
    addOnsDb,
    addOnsTracker,
    variantToIgnore,
    groupVairantsTracker
  );
  const collectionAddOns = getCollectionAddOns(
    PAGE.Product,
    id,
    addOnsDb,
    addOnsTracker,
    variantToIgnore,
    groupVairantsTracker
  );
  addOns.push.apply(addOns, collectionAddOns);
  if (
    type === PrepareType.Popups ||
    (type === PrepareType.Addons && !options.disableProductPageCatalogAddOns)
  ) {
    const allProductAddOns = getCatalogAddOns(
      addOnsDb,
      addOnsTracker,
      variantToIgnore,
      groupVairantsTracker
    );
    addOns.push.apply(addOns, allProductAddOns);
  }
  return addOns;
}

function prepareItems(result, id, selectedVariantId, type) {
  const { options, addOnsDb, popups } = getAppProps();

  let variantToIgnore = {};
  const groupVairantsTracker = options.groupVariants ? {} : null;

  const addOns = prepareAddons({
    addOnsDb: type === PrepareType.Addons ? addOnsDb : popups,
    id,
    selectedVariantId,
    variantToIgnore,
    groupVairantsTracker,
    options,
    type
  });

  if (groupVairantsTracker) {
    for (let groupedVariant in groupVairantsTracker) {
      addOns.push(groupVairantsTracker[groupedVariant]);
    }
  }
  return sortAddOns(addOns);
}

export default function productPage() {
  const { options } = getAppProps();

  try {
    addOnAddToCartActionHander(false, options);
    trackItemsInCart();
    injectStyles(options, PAGE.Product);

    const res = window.upsell.product.details;
    let result = {
      product: res
    };
    if (result && result.product) {
      const availableVariants = result.product.variants.filter(
        i => i.available
      );
      const allVariants = result.product.variants;
      const id = result.product.id;
      let selectedVariantId = availableVariants.length
        ? availableVariants[0].id
        : allVariants[0].id;

      const url = new URL(document.URL);
      const isVariantUrl = url.searchParams.get('variant');
      if (isVariantUrl) {
        selectedVariantId = isVariantUrl;
      }

      const sortedAddOns = prepareItems(
        result,
        id,
        selectedVariantId,
        PrepareType.Addons
      );
      const sortedPopupAddOns = prepareItems(
        result,
        id,
        selectedVariantId,
        PrepareType.Popups
      );
      const visiblePopupAddons = sortedPopupAddOns.length > 0;
      if (visiblePopupAddons) {
        setPopUpData({
          product: res,
          addOns: sortedPopupAddOns
        });
      } else {
        setPopUpData(null);
      }
      renderer(
        !isNoProduct() ? sortedAddOns : [],
        sortedPopupAddOns,
        PAGE.Product,
        result.product.handle
      );
      renderDiscountOffer();
    }
  } catch (err) {
    removeUpsell();
    errorHandler(err);
  }
}
