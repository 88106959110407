// om sai ram

import loadScript from './loadScript';
import app from './app';
import errorHandler from './errorHandler';
import { isProductPage, isCartPage, isInternalJqueryValid, isOrderStatusPage } from './utils';
import { isDevMode } from './devMode';

function init() {
  let loadJquery = false;
  if (IS_LOGGING) {
    loadLogging();
  }
  if (typeof jQuery === 'undefined') {
    loadJquery = true;
  } else {
    let ver = 0.0;
    if (jQuery.fn && typeof jQuery.fn.jquery === 'string') {
      ver = parseFloat(
        jQuery.fn.jquery.substring(0, jQuery.fn.jquery.lastIndexOf('.'))
      );
    }

    if (!isInternalJqueryValid()) {
      ver = 0.0;
    }

    if (ver >= 2.2) {
      window.jUpsell = jQuery;
      loadApp();
    } else {
      loadJquery = true;
    }
  }

  if (loadJquery) {
    loadScript(
      '//ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js',
      function() {
        window.jUpsell = jQuery.noConflict(true);
        loadApp();
      }
    );
  }

  function loadApp() {
    try {
      //createSlider();
      isDevMode();
      if (isProductPage() || isCartPage() || isOrderStatusPage()) {
        detectVariantChanges();
        app();
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function detectVariantChanges() {
    let firstTimeUrl = document.URL;
    document.addEventListener('change', function() {
      let currentPageUrl = document.URL;
      const url = new URL(currentPageUrl);
      const isVariantUrl = url.searchParams.get('variant');
      currentPageUrl = isVariantUrl ? currentPageUrl : isVariantUrl;
      if (currentPageUrl && firstTimeUrl !== currentPageUrl) {
        firstTimeUrl = currentPageUrl;
        window.upsell.variantChanged = true;
        app();
      }
    });
  }

  function loadLogging() {
    const ignoreErrors = [
      "undefined is not an object (evaluating 't.shopConfig.pushe_app_id')",
      "n is not a function. (In 'n()', 'n' is 2)",
      "t[n] is not a function. (In 't[n](function() {++n === t.length ? e() : seq(t, e, n)})', 't[n]' is undefined)",
      "undefined is not an object (evaluating 'a._swAction.indexOf')",
      "Cannot set properties of null (setting 'order')",
      "null is not an object (evaluating 'i.order=o.order')",
      "Cannot read properties of undefined (reading 'indexOf')",
      "Cannot read properties of undefined (reading 'pushe_app_id')",
      'SyntaxError: The string did not match the expected pattern.',
      "undefined is not an object (evaluating '_0xfa16x23[_0xf42f[67]]')",
      "Cannot read properties of undefined (reading 'url')",
      'There are no appropriate offers',
      'null is not an object (evaluating \'Object(u.d)("dummy-chat-button").addEventListener\')',
      '{"error":"Order fulfillment status is FULFILLED","type":"expired"}',
      '$ is not a function',
      "null is not an object (evaluating 't.contentWindow.postMessage')",
      'Vimeo is not defined',
      'SecurityError: The operation is insecure.',
      'SyntaxError: The string did not match the expected pattern.',
      "SecurityError: Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document."
    ];

    function matchAny(str) {
      const strLower = str.toLowerCase();

      for (const strIter of ignoreErrors) {
        if (strLower.includes(strIter.toLowerCase())) {
          return true;
        }
      }

      return false;
    }

    loadScript(
      '//browser.sentry-cdn.com/5.6.3/bundle.min.js',
      function() {
        Sentry.init({
          dsn:
            'https://fe5cfc0017ae46c28aa4231ee2669fce@o731358.ingest.sentry.io/5783448',
          environment: 'prod',
          defaultIntegrations: false,
          sampleRate: 0.1,
          denyUrls: [/lealanasbananas\.com/i],
          beforeSend: (event, hint) => {
            const error = hint.originalException;
            if (error && error.message && matchAny(error.message)) {
              // do not send known issue
              return null;
            }
            return event;
          }
        });

        Sentry.setContext('shop_data', {
          shop_name: Shopify ? Shopify.shop : undefined,
          location: window.location.href
        });
      },
      {
        integrity:
          'sha384-/Cqa/8kaWn7emdqIBLk3AkFMAHBk0LObErtMhO+hr52CntkaurEnihPmqYj3uJho',
        crossOrigin: 'anonymous'
      }
    );
  }
}

// Prevent 2 times loading
if (!window.jUpsell) {
  init();
}
