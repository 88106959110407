import { createAddOnRecord } from '../addOnsUtil';
import { getHookCheckoutItems } from '../pages/cartPageInjector/utils';
import { getAppProps, isCartPage, isProductPage } from '../utils';
import { getItemDiscountForOffer } from '../common/discountOffer';

export function isItemProductExists(item) {
  const { productId, variantId } = item;

  if (isCartPage()) {
    const items = getHookCheckoutItems();

    if (productId && typeof variantId === 'number') {
      if (
        items.find(
          v => v.product_id === productId && v.variant_id === variantId
        )
      ) {
        return true;
      }
    } else {
      if (items.find(v => v.product_id === productId)) {
        return true;
      }
    }

    return false;
  } else {
    // Skip check for product page.
    // We use this for popUp and already have attached to.
    return true;
  }
}

/*
* STRUCTURE EXAMPLE
* 6846871765179|40706905309371
    dark-wall-table|40707005939899: "40707005939899||Desc|20|false"

  product_id|variant_id
  *  name-id|variant_id: variant_id|order|description text|discount|specified.
* */

export function getTargetCollection() {
  const details = window.upsell.product.details;
  const availableVariants = details.variants.filter(i => i.available);
  const allVariants = details.variants;
  const id = details.id;
  let selectedVariantId = availableVariants.length
    ? availableVariants[0].id
    : allVariants[0].id;

  const url = new URL(document.URL);
  const isVariantUrl = url.searchParams.get('variant');
  if (isVariantUrl) {
    selectedVariantId = isVariantUrl;
  }

  const product = id;
  const variantId = allVariants.length > 1 ? selectedVariantId : null;

  if (product && variantId) {
    return `${product}|${variantId}`;
  } else {
    return `${product}`;
  }
}

export function getTargetProductId() {
  return (
    (window.upsell &&
      window.upsell.product &&
      window.upsell.product.details &&
      window.upsell.product.details.id.toString()) ||
    null
  );
}

function findIn(addons, item, targetCollection) {
  const { variant_id } = item;

  for (const collection in addons) {
    if (!targetCollection || collection === targetCollection) {
      const itemsPerCollection = addons[collection];
      for (const key in itemsPerCollection) {
        const parsed = createAddOnRecord(itemsPerCollection, key);
        if (parsed.ids.includes(variant_id)) {
          const [productId, variantId] = collection.split('|');

          const ret = parsed;
          ret.productId = parseInt(productId);

          if (variantId) {
            ret.variantId = parseInt(variantId);
          }
          // If attached product exists?
          if (isItemProductExists(ret)) {
            return ret;
          }
        }
      }
    }
  }

  return null;
}

export function getItemFromAddon(addons, item) {
  let targetCollection = null;

  if (isProductPage()) {
    targetCollection = getTargetCollection();
  }

  // Find in everywhere
  let res = findIn(addons, item, targetCollection);

  // try find in product
  if (!res && isProductPage()) {
    const collection = getTargetProductId();
    if (collection) {
      res = findIn(addons, item, collection);
    }
  }

  // Find in all
  if (!res) {
    res = findIn(addons, item, 'all');
  }

  return res;
}

export function getItemPropertyById(addons, item, key, defaultValue) {
  const itemAddon = getItemFromAddon(addons, item);

  if (!itemAddon || itemAddon[key] === undefined) return defaultValue;

  return itemAddon[key];
}

export function getItemDiscountById(addons, item) {
  const itemAddon = getItemFromAddon(addons, item);

  if (!itemAddon || !itemAddon.discount) return 0;

  return itemAddon.discount;
}

export function getItemDiscount(item) {
  const { addOnsDb, popups } = getAppProps();

  // !IMPORTANT: Don't change checking order

  // check in discount offer
  const discountOffer = getItemDiscountForOffer(item);

  // check in addons
  const discountAddon = getItemDiscountById(addOnsDb, item);

  // check in popups
  const discountPopup = getItemDiscountById(popups, item);

  // Get max value
  return Math.max(...[discountOffer, discountAddon, discountPopup]);
}

function findAvailableWithCart(addons, item, targetCollection) {
  const { variant_id } = item;

  for (const collection in addons) {
    if (!targetCollection || collection === targetCollection) {
      const itemsPerCollection = addons[collection];
      for (const key in itemsPerCollection) {
        const parsed = createAddOnRecord(itemsPerCollection, key);
        if (parsed.ids.includes(variant_id) && parsed.availableWithCart) {
          const [productId, variantId] = collection.split('|');

          const ret = parsed;
          ret.productId = parseInt(productId);

          if (variantId) {
            ret.variantId = parseInt(variantId);
          }
          return ret;
        }
      }
    }
  }

  return null;
}

export function getItemAvailableWith(item) {
  const { addOnsDb, popups } = getAppProps();

  // check in addons
  let target = findAvailableWithCart(addOnsDb, item, null);

  // check in popups
  if (!target) {
    target = findAvailableWithCart(popups, item, null);
  }
  if (target) {
    const { productId, variantId } = target;

    const items = getHookCheckoutItems();
    const found = items.find(v => {
      if (v.product_id === productId && !variantId) {
        return true;
      }

      if (v.product_id === productId && v.variant_id === variantId) {
        return true;
      }

      return false;
    });
    if (found) {
      return {
        product: found,
        target
      };
    }
  }

  return null;
}

export function getItemFromAnyAddon(item) {
  const { addOnsDb, popups } = getAppProps();

  // !IMPORTANT: Don't change checking order

  // check in addons
  const addonItem = getItemFromAddon(addOnsDb, item);
  if (addonItem) {
    return addonItem;
  }

  // check in popups
  const popupItem = getItemFromAddon(popups, item);
  if (popupItem) {
    return popupItem;
  }

  return null;
}

window.upsellGetMainProductRecordId = getTargetCollection;
