import CssClasses from '../../common/cssClasses';

export function styles() {
  return `
<style>
#un-modal-preview{
    border-radius:0px !important;
}
#un-modal-content{
    width: 500px;
    font-size:13px;
    line-height:1.5;
}
#un-modal-content, #un-modal-content span, #un-modal-content button, #un-modal-content a, #un-modal-content select, #un-modal-content input{
    font-family: -apple-system, BlinkMacSystemFont, 'Mulish', 'Segoe UI', Roboto, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
#un-modal-content .checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    position: absolute;
    right: -7px;
    top: -7px;
    z-index:9;
}
#un-modal-content .checkmark_circle {
    display: block;
    position: absolute;
    width:22px;
    height:22px;
    background-color: green;
    border-radius:11px;
    left:0;
    top:0;
}
#un-modal-content .checkmark_stem {
    display: block;
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}
#un-modal-content .checkmark_kick {
    display: block;
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}
#upsell-product-table{
    list-style: none;
    padding: 0;
    display: block;
    max-height: calc(100vh - 45vh);
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid rgb(240, 240, 240);
    font-size: unset;
    line-height: unset;
}
.upsell-product-list-item{
    position: relative;
    margin: 0 40px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
}

/* NEW */
.upsell-product-list-item-vertical {
    flex-direction: column;
}

.upsell-product-list-item-vertical .upsell-product-info {
    text-align: center;
    width: 140px;
}

.upsell-product-list-item-vertical 
.upsell-product-image {
    max-width: 150px;
}

.upsell-product-original-price-vertical {
    margin-right: 0px !important;
}

.uppsell-product-checkbox-left-vertical {
    position: absolute;
    transform: translateX(-80px);
}

.upsell-product-prices-vertical {
    justify-content: center;
}

.upsell-product-container-wrapper-vertical {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 450px) {
    .upsell-product-list-item-horizontal {
        flex-direction: column;
    }
    
    .upsell-product-list-item-horizontal > .upsell-product-info {
        text-align: center;
        margin-bottom: 10px;
    }
    
    .upsell-product-title-horizontal {
        margin: 0 auto;
        text-align: center;
    }
    
    .upsell-product-list-item-horizontal > .upsell-product-cart-fields {
        margin: 0px;
    }
    
    .upsell-product-list-item-horizontal > 
    .upsell-product-info > 
    .upsell-product-prices > 
    .upsell-product-price {
        margin-left: 10px;
    }
    
    .upsell-product-list-item-horizontal > 
    .upsell-product-info > 
    .upsell-product-prices {
        justify-content: center;
    }
    
    .upsell-product-info .upsell-variant-options {
        display: block;
        max-width: 120px;
        margin: 0 auto;
    }
}

.upsell-product-info .upsell-variant-options > select {
    width: 90px;
}

.upsell-product-desc-horizontal {
    margin-top: 0px;
}

.upsell-product-desc-vertical {
    margin-top: 0px;
}
    
/* NEW END */

.upsell-product-list-item:not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(240, 240, 240);
}
#un-modal-content input[name='quantity']:disabled{
    color: rgb(170,170,170) !important;
}
.added button.upsell-button{
    color: white !important;
    background-color: rgb(200,200,200) !important;
}
.added button.upsell-button svg{
    display: none !important;
}
button.upsell-remove-button{
    background-color:transparent !important;
    border: none !important;
    padding: 1px 6px !important;
}
button:focus, select:focus, input:focus { outline: none !important; }
.upsell-product-list-item.purchased-product{
    padding: 15px 55px;
    margin: 0 0 10px 0;
    background-color: rgb(233, 233, 233);
    border-bottom: 1px solid rgb(233, 233, 233);
}
.purchased-product .upsell-product-image-container{
    height: 60px;
    width: 60px;
}
.purchased-product .upsell-product-title{
    font-weight: 300;
}
.upsell-product-image-container{
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    flex: 0 0 auto;
    border-radius: 0px;
}
.upsell-product-image-wrap{
    position:relative;
}
.purchased-product .upsell-product-image-wrap{
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
}
.upsell-product-image{
    max-width:100px;
    max-height:80px;
}
.purchased-product .upsell-product-image{
    max-height: 60px;
    max-width: 60px;
}
.upsell-product-info{
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    margin: 0px 16px;
    text-align: left;
}
.purchased-product .upsell-product-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 16px;
}
.purchased-product .upsell-product-title{
    margin-right: 16px;
}
.upsell-product-prices{
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    margin: 0px 0px 5px;
    flex-wrap: wrap;
}
.purchased-product .upsell-product-prices{
    margin: 0px 0px 0px;
}
.purchased-product .upsell-product-price{
    margin-bottom: 0px;
}
.upsell-product-link{
    text-decoration: none;
}
.upsell-product-cart-fields{
    display: flex;
    margin: 0 0 0 12px;
}
/* NEW */
.upsell-product-cart-fields-vertical {
    display: flex;
    margin: 0px 0 8px;
}
#un-modal-title{
    margin: 20px 10px 10px;
    text-align:center;
    font-size:2em;
    font-weight: 600;
    color:black;
    padding: 0 40px;
    max-height: 80px;
    overflow-y: auto;
}
#un-modal-description{
    font-size:1.2em;
    line-height:1.5;
    color:black;
    padding: 0 5%;
    max-height: calc(100vh - 85vh);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom:20px;
}

/*Un modal for full image start*/
#un-modal-background-for-full-img {
    display:none;
    width:100%;
    margin-bottom:20px;
    z-index: 2147483648;
}

#un-modal-content-for-full-img{
    background-color: transparent;
    margin: 5% auto;
    position: relative;
    border-radius: 0;
    max-height: 100%;
}
#un-modal-close-btn-for-full-img{
    font-size: 20px;
    line-height: 34px;
    color: #000;
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -15px;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.88);
    border: 1px solid #ccc;
}

#upsell-popup-close-link-for-full-img{
    color: #bbbbbb;
    font-size:14px;
}

#upsell-popup-close-link-for-full-img:hover{
    background-color:transparent;
}

.upsell-product-image-for-full-img{
    margin: 0 auto;
    display: block;
}
/*Un modal for full image end*/
#un-modal-background {
    display:none;
    background-color: rgba(0,0,0,0.7);
    width:100%;
    margin-bottom:20px;
}
.un-manual-modal-container{
    display: flex !important; /* Hidden by default */
    align-items:center;
    position: fixed; /* Stay in place */
    z-index: 2147483648; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
#un-modal-content{
    background-color: #fefefe;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px 0px;
    margin: 20px; /* 15% from the top and centered */
    padding-bottom: 10px;
    border-radius:0px;
    text-align:center;
    box-sizing: border-box;
}
#un-modal-close-btn{
    float:right;
    font-size:1.8em;
    color: black;
    cursor:pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    display:flex;
}
.upsell-product-title{
    font-size: 1.2em;
    color: black;
    font-weight: 700;
}
/* NEW */
.upsell-product-title-max {
    max-width: 180px;
}
.upsell-product-qty{
    font-size: 1em;
    margin: 0 0 0 10px;
    color: black;
}
.upsell-product-price, .upsell-product-original-price{
    display: inline-block;
}
.upsell-product-original-price{
    width: 100%;
    text-decoration: line-through;
    font-size: 1.15em;
    margin-right: 10px;
    color:rgb(154,154,154);
}
.upsell-product-price{
    margin-bottom: 3px;
    font-size:1.2em;
    color:black;
}
.upsell-product-savings{
    font-size: 10px;
    background-color: green;
    color: white;
    border-radius: 7px;
    padding: 0 5px;
    margin-bottom: 5px;
}
.upsell-product-variant-field:not([disabled]) {
    cursor: pointer;
}
.upsell-product-variant-field{
    font-size: 1em !important;
    line-height: 1.2 !important;
    margin: 0 10px 0 0 !important;
    min-width: 70px !important;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3E%3Cpath fill='%23666' d='M0 0l3.5 4.4C3.7 4.4 7.1 0 7.1 0H0z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right 10px center !important;
    background-color: rgb(255, 255, 255) !important;
    background-size: initial !important;
    padding: 8px 28px 8px 14px !important;
    border-radius: 0px;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.1)  !important;
}
.upsell-now-quantity-field{
    width: 60px !important;
    font-size: 1em !important;
    line-height: 1.2 !important;
    margin: 0 10px 0 0 !important;
    padding: 8px 4px !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0px;
    border: 1px solid !important;
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
}
.upsell-button{
    background:black;
    color:white;
    font-size:1.1em;
    display: flex;
    width: initial;
    border-radius: 3px;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 8px 14px;
    border: 0px;
    border-radius: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    box-shadow: none !important;
}
.upsell-button:hover{
    background-color: black;
}
#upsell-popup-continue-button{
    padding: 8px 20px;
    background-color: white;
    border: 1px solid black;
    color:black;
    white-space: nowrap;
    cursor: pointer;
}
.upsell-product-add-to-cart svg{
    width: 15px;
    margin: 0px 4px 0px 0px;
}
.upsell-button svg{
    width: 15px;
    margin: 0px 4px 0px 0px;
    vertical-align:middle;
}
#un-modal-content .add-all-to-cart{
    display: none !important;
}
.upsell-now-continue{
    border:1px solid black;
    background-color: white;
    color: black;
}
#upsell-popup-close-link{
    color: #bbbbbb;
    font-size:14px;
}
#upsell-popup-close-link:hover{
    background-color:transparent;
}
#un-modal-content .disabled, .upsell-product-add-to-cart.disabled, .upsell-product-add-to-cart.disabled:hover{
    background: #f4f6f8 !important;
    color: #919eab !important;
    border: none;
}
#un-checkout-redirect-msg{
    display:block;
    clear:both;
    color:black;
    font-weight:bold;
}
.spinner{
    z-index:999 !important;
}
.upsell-product-disabled{
    background: #cccccc;
}
.un-cart-bar{
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #37ad29;
    color: #FFFFFF;
}
#un-modal-content .popup-cursor{
    cursor: pointer;
}
#un-modal-content .qty-alert-popup-field{
    background-color: red;
    font-size: 10px;
    line-height: 14px;
    margin-top: -5px;
    display: block;
    color: #fff;
    padding: 0 5px;
    border-radius: 0px;
    position: absolute;
    max-width: 200px;
    text-align: center;
    margin-top:33px;
}
.upsell-footer-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
}
.upsell-footer-buttons button{
    display: inline;
}
.upsell-footer-buttons a, .upsell-footer-buttons button{
    margin: 8px 20px;
}
.upsell-continue-text {
    color: rgba(0,0,0,.7);
    text-decoration: underline;
}
.upsell-now-no-thanks{
    color: rgba(0,0,0,.7);
    text-decoration: none;
}
@media only screen and (max-width: 374px){
    #un-modal-content{
        font-size:12px;
    }
    .upsell-product-list-item{
        margin: 0px;
    }
    .purchased-product .upsell-product-info{
        flex-basis: 50%;
    }
    .purchased-product .upsell-product-title{
        font-size:1em;
    }
    .purchased-product .upsell-product-prices{
        margin-bottom:0px;
    }
    .purchased-product .upsell-product-price{
        font-size:1.2em;
    }
    #upsell-product-table{
        max-height: calc(100vh - 68vh);
    }
    #un-modal-description{
        max-height: calc(100vh - 93vh);
    }
}
@media only screen and (min-width: 375px) and (max-width: 699px){
    .upsell-product-list-item{
        margin: 0 20px;
    }
}
@media only screen and (max-width: 700px){
    #un-modal-content .qty-alert-popup-field{
        background-color: red;
        font-size: 10px;
        line-height: 14px;
        margin-top: -5px;
        display: block;
        color: #fff;
        padding: 0 5px;
        border-radius: 0px;
        position: absolute;
        max-width: 200px;
        text-align: center;
        margin-top:33px;
    }
    .upsell-product-image-container{
        width: 60px;
        height: auto;
    }
    .upsell-product-add-to-cart {
        width: 100%;
    }
    .upsell-product-variant-field{
        width: 100%;
    }
    .upsell-product-list-item{
        padding: 15px 20px;
    }
    .upsell-product-list-item:first-child{
        padding: 15px 20px;
    }
    #upsell-product-table{
        max-height: calc(100vh - 55vh);
    }
    .purchased-product .upsell-product-info{
        flex-direction:column;
        align-items:initial;
    }
    .upsell-product-image{
        max-width:100%;
    }
    .upsell-product-price{
        font-size: 1.2em;
        margin-right: 10px;
    }
    .upsell-product-original-price{
        font-size: 1.1em;
        margin-right: 10px;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 701px){
    #un-modal-content {
        max-width: 650px;
    }
}
@media only screen and (min-width: 1001px){
    #un-modal-content {
        max-width: 800px;
    }
}
.added {
    animation: added 0.15s linear;
}
/* Adding to Cart Animations */
@keyframes confirm {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95)
    }

    75% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes added {
    0% {
        transform: scaleX(1);
    }

    30% {
        transform: scaleX(1);
    }

    50% {
        transform: scaleX(1.05)
    }

    75% {
        transform: scaleX(1.1);
    }

    100% {
        transform: scaleX(1);
    }
}

@keyframes upgraded {
    0% {
        margin-top: -0.3em;
    }

    25% {
        width: auto;
        margin: 0 17px;
        margin-top: -0.3em;
    }

    70% {
        width: auto;
        margin: 0 17px;
        margin-top: -0.3em;
        z-index: 0;
    }

    85% {
        z-index: 1;
        width: auto;
        margin: 0 17px;
        margin-top: 0.7em;
    }

    100% {
        z-index: 1;
        width: auto;
        margin: 0 17px;
        margin-top: -6.3em;

    }
}

@keyframes price-upgraded {
    0% {
        opacity: 1;
    }

    15% {
        opacity: 0;
    }

    85% {
        opacity: 0;
        float: right;
        margin-top: -1.8em;
        margin-right: 1.9em;
        font-size: 21px;
    }

    100% {
        opacity: 1;
        float: right;
        margin-top: -1.8em;
        margin-right: 1.9em;
        font-size: 21px;
    }
}

/* NEW */
.upsell-product-add-to-cart {
    cursor: pointer;
    min-width: 70px;
}

.upsell-product-add-to-cart-checkbox {
    margin-top: 10px;
}

/* LOADER */
.upsell-popup-loader,
.upsell-popup-loader:before,
.upsell-popup-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.upsell-popup-loader {
  color: grey;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.upsell-popup-loader:before,
.upsell-popup-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.upsell-popup-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.upsell-popup-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.${CssClasses.variantDropdown} > select:focus-visible,
.${CssClasses.variantDropdown} > select:focus {
    outline: 0 !important;
    box-shadow: none !important;
}
</style>
  `;
}
