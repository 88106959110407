export const cartDetailsUrl = IS_SHOPIFY_ENV
  ? `${window.location.protocol}//${window.location.hostname}/cart.json`
  : '/data/cart.json';

window.upsell = window.upsell || {};

export function setCurrentCart(result) {
  window.upsell.currentCart = {};
  if (result && result.items) {
    for (let i = 0; i < result.items.length; i++) {
      const product = result.items[i];
      window.upsell.currentCart[product.variant_id] = product.quantity;
    }
  }
}

export function updateCartItemsDetails(result) {
  window.upsell.currentCartItemsDetails = [];
  if (result && result.items) {
    window.upsell.currentCartItemsDetails = result.items;
  }
}

export function getCartItemsDetails() {
  return window.upsell.currentCartItemsDetails || [];
}

export function getAddOnQuantity(variantId) {
  if (
    window.upsell &&
    window.upsell.currentCart &&
    window.upsell.currentCart[variantId]
  ) {
    return window.upsell.currentCart[variantId];
  }
  return 0;
}
