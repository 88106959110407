import { isValidValues } from './utils';

const TIMEOUT_MINUTES = 15;
const CACHE_BUST_KEY = 'upsell-cache-clear';
const KEYS = {
  options: 'upsell-options',
  addOns: 'upsell-addOns',
  timestamp: 'upsell-cache-timestamp',
  popups: 'upsell-popups',
  discount_offer: 'upsell-discount-offer',
  discount_offer_collection: 'upsell-discount-offer-collection',
  discount_offer_catalog: 'upsell-discount-offer-catalog'
};
export function get() {
  try {
    if (!window.sessionStorage) return;
    if (isTimedOut()) return;
    if (bustCache()) {
      clear();
      return;
    }

    const optionsVal = sessionStorage.getItem(KEYS.options);
    const addOnsVal = sessionStorage.getItem(KEYS.addOns);
    const popupsVal = sessionStorage.getItem(KEYS.popups);
    const discount_offer = sessionStorage.getItem(KEYS.discount_offer);
    const discount_offer_collection = sessionStorage.getItem(
      KEYS.discount_offer_collection
    );
    const discount_offer_catalog = sessionStorage.getItem(
      KEYS.discount_offer_catalog
    );

    if (optionsVal && addOnsVal) {
      return {
        options: JSON.parse(optionsVal),
        addons: JSON.parse(addOnsVal),
        popups: JSON.parse(popupsVal),
        discount_offer: JSON.parse(discount_offer),
        discount_offer_collection: JSON.parse(discount_offer_collection),
        discount_offer_catalog: JSON.parse(discount_offer_catalog)
      };
    }
  } catch (ex) {
    return;
  }
}

export function set(data) {
  if (bustCache()) return;

  if (isValidValues(Object.values(data))) {
    if (!data.options.caching) return;

    try {
      if (!window.sessionStorage) return;

      sessionStorage.setItem(KEYS.timestamp, `${new Date().getTime()}`);

      for (const key in data) {
        const val = data[key];
        sessionStorage.setItem(KEYS[key], JSON.stringify(val));
      }

      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }
}

function isTimedOut() {
  const timestampVal = sessionStorage.getItem(KEYS.timestamp);
  if (timestampVal) {
    const timestamp = parseInt(timestampVal);
    let diff = (new Date().getTime() - timestamp) / 1000;
    diff /= 60;
    const minutes = Math.abs(Math.round(diff));
    return minutes >= TIMEOUT_MINUTES;
  }
}

function clear() {
  for (const key in KEYS) {
    sessionStorage.removeItem(KEYS[key]);
  }
}

function bustCache() {
  return window.location.href.toLocaleLowerCase().indexOf(CACHE_BUST_KEY) >= 0;
}
