import { styles } from './style';
import { render } from './render';
import { upsellInputPrefix, modalId, popUpId } from './data';
import {
  addOrUpdateAddon,
  combineWithSelectedAddons,
  getAppProps
} from '../../../utils';
import {
  getSelectedPopUpAddons,
  isAddonStored,
  isPopupsLoaded,
  isShowMainProduct,
  removeSelectedAddon,
  setPopupsLoaded,
  storeSelectedAddon
} from './common';
import { updateAnalytics } from '../../../utils/analytics';
import { TRANSLATE_IDS, translateText } from '../../../utils/translate';
import { ANALYTIC_CATEGORIES } from '../../../analytics';
import showError from '../../../utils/error';
import { ajaxQueryAddToCart } from '../../../api/common';
import CssClasses from '../../common/cssClasses';
import { Element_Ids } from '../../../templates';
import { PAGE } from '../../../domain';
import {
  CLICK_HOOK_ATTR_ADD_TO_CART,
  getForm,
  getSubmitButton
} from '../../../render/common/utils';
import { UPSELL_ADD_TO_CART } from '../../../eventsApi';

let visible = false;
let themeInstance = null;
let updateInterval = null;

let HOOKED_ADD_TO_CART = false;

function setThemeInstance(instance) {
  themeInstance = instance;
}

function getThemeInstance() {
  return themeInstance;
}

function outsideClick(event, notelem) {
  notelem = jUpsell(notelem); // jquerize (optional)
  // check outside click for multiple elements
  var clickedOut = true,
    i,
    len = notelem.length;
  for (i = 0; i < len; i++) {
    // eslint-disable-next-line eqeqeq
    if (event.target == notelem[i] || notelem[i].contains(event.target)) {
      clickedOut = false;
    }
  }
  if (clickedOut) return true;
  else return false;
}

function clickHandler(e) {
  var modal = document.getElementById(modalId);

  if (outsideClick(e, modal) && !isAddToCartHooked()) {
    addPopUpMainProduct();
  }
}

function closePopUp(callEvent) {
  if (visible) {
    document.getElementById(popUpId).outerHTML = '';
    visible = false;

    window.removeEventListener('click', clickHandler);

    if (updateInterval) {
      window.clearInterval(updateInterval);
    }

    if (callEvent) {
      const instance = getThemeInstance();
      if (instance && instance.onClose) {
        instance.onClose();
      }
    }
  }
}

function popupRender(forceRender) {
  const html = `
    ${styles()}
    ${render()}
  `;

  // Shall we re-render?
  if (forceRender) {
    const div = document.getElementById(popUpId);
    div.innerHTML = html;

    return;
  }

  var div = document.createElement('div');
  div.id = popUpId;
  div.innerHTML = html;
  document.body.insertBefore(div, document.body.firstChild);
}

function showPopUp(instance) {
  if (visible) {
    closePopUp();
  }

  // clean up
  window.upsellSelectedAddons = {};

  setThemeInstance(instance);
  popupRender();

  if (!isPopupsLoaded()) {
    const startTime = window.performance.now();
    const timeoutTime = 5000;

    updateInterval = window.setInterval(() => {
      if (isPopupsLoaded()) {
        popupRender(true);
        window.clearInterval(updateInterval);
        updateInterval = null;
      }

      // If is timeout, remove loading spinner
      const curTime = window.performance.now();
      if (curTime - startTime > timeoutTime) {
        setPopupsLoaded(true);
      }
    }, 100);
  }

  // Fix close bug after showing (click event is so fast)
  window.setTimeout(() => {
    window.addEventListener('click', clickHandler);
  }, 100);

  visible = true;
}

function setPopUpItemState(id, state) {
  const { options } = getAppProps();
  const type = options.popUpAddOnType;

  const div = jUpsell('#upsell-product-' + id);

  if (type === 'button' || type === 'buttonQtyBox') {
    if (state) {
      div.removeClass('added');
    } else {
      div.addClass('added');
    }

    div.find(jUpsell('.checkmark')).css({ display: state ? 'none' : 'block' });
    div
      .find(jUpsell('.upsell-remove-button'))
      .css({ display: state ? 'none' : 'block' });
    div
      .find(jUpsell('.upsell-product-add-to-cart span'))
      .text(
        state
          ? translateText(TRANSLATE_IDS.popUpAddItemText)
          : translateText(TRANSLATE_IDS.popUpRemoveItemText)
      );
  }

  const input = div.find(jUpsell('.upsell-now-quantity-field'));
  if (input) {
    input.prop('disabled', !state);
  }

  const dropdown = div.find(jUpsell(`.${CssClasses.popUpVariantSelector}`));
  if (dropdown) {
    dropdown.prop('disabled', !state);
  }
}

/* STORE */
function togglePopUpAddonItem(args) {
  const { id, checkbox } = args;
  let variantId = id;

  // Get variant id
  const dropdown = document.querySelector(
    `#${Element_Ids.popUpVariantSelector}${id}`
  );
  if (dropdown) {
    variantId = dropdown.value;
  }

  // Get quantity
  let quantity = 1;
  const input = document.getElementById(`${upsellInputPrefix}${id}`);
  if (input) {
    quantity = parseInt(input.value);
  }

  let isAdded = isAddonStored(id);
  if (!isAdded) {
    storeSelectedAddon(id, { quantity, id: parseInt(variantId), checkbox });
    setPopUpItemState(id, false);
  } else {
    removeSelectedAddon(id);
    setPopUpItemState(id, true);
  }

  // Don't add checkbox addons
  if (!checkbox) {
    const { options } = getAppProps();

    ajaxQueryAddToCart({
      isRemove: isAdded,
      qty: quantity,
      variantId
    }).fail(function(xhr) {
      showError(xhr, options, 'Errors Occured!');
    });
  }
}

function addPopUpMainProduct() {
  const instance = getThemeInstance();

  if (instance) {
    const order = new Order();

    // main product
    if (isShowMainProduct()) {
      triggerAddToCartButton();
      order.mainProduct = true;
    }

    order.outsideClick = true;
    order.query();
  }

  // hide window
  closePopUp();
}

function isAddToCartHooked() {
  return HOOKED_ADD_TO_CART;
}

function triggerAddToCartButton() {
  const { options } = getAppProps();

  const page = PAGE.Product;
  const cartForm = getForm(page, options);
  if (cartForm) {
    const submitEl = getSubmitButton(options, cartForm, page);
    if (submitEl) {
      HOOKED_ADD_TO_CART = true;
      submitEl.setAttribute(CLICK_HOOK_ATTR_ADD_TO_CART, 'true');
      submitEl.click();
      HOOKED_ADD_TO_CART = false;
    }
  }
}

function addPopUpSelectProducts(ignoreAddToCart) {
  // add all selected up-sell addons to cart
  const instance = getThemeInstance();
  if (instance) {
    const order = new Order();
    order.ignoreAddToCart = ignoreAddToCart;

    // main product
    if (isShowMainProduct()) {
      triggerAddToCartButton();
      order.mainProduct = true;
    }

    order.query();
  }

  // hide window
  closePopUp();
}

function combineWithCheckboxSelectedAddons(addons) {
  // get checkboxes
  const popupSelectedItems = getSelectedPopUpAddons();
  // eslint-disable-next-line no-unused-vars
  for (const [_, item] of Object.entries(popupSelectedItems)) {
    if (item.checkbox) {
      addOrUpdateAddon(addons, item);
    }
  }
}

class Order {
  constructor() {
    this.items = [];
    this.mainProduct = false;
    this.ignoreAddToCart = false;
    this.outsideClick = false;
  }

  query() {
    // Check if we should add main product (we have to wait some time)
    if (this.mainProduct) {
      let called = false;

      const callPost = () => {
        if (called) {
          return;
        }

        called = true;
        this.queryProcess();
      };

      document.addEventListener(UPSELL_ADD_TO_CART, () => {
        callPost();
      });

      // eslint-disable-next-line no-undef
      setTimeout(() => {
        callPost();
      }, 1000);
    } else {
      this.queryProcess();
    }
  }

  queryProcess() {
    const instance = getThemeInstance();

    // Add selected previously addons
    combineWithSelectedAddons(this.items);

    // Add checkbox addons from popup only if we click on add to cart,
    // not from outside click if we close window
    if (!this.outsideClick) {
      combineWithCheckboxSelectedAddons(this.items);
    }

    // If we added any items, show message
    const selected = getSelectedPopUpAddons();
    if (
      this.mainProduct ||
      this.items.length > 0 ||
      Object.keys(selected).length > 0
    ) {
      instance._addItemToCartFinal({
        items: this.items,
        outsideClick: this.outsideClick,
        ignoreAddToCart: this.ignoreAddToCart,
        mainProduct: this.mainProduct
      });
    } else {
      instance.onEmpty();
    }

    // Update analytics stuff
    updateAnalytics(this.items, ANALYTIC_CATEGORIES.Popup);
  }
}

/* API */
window.upsellClosePopUp = closePopUp;
window.upsellShowPopUp = showPopUp;
window.upsellRemovePopUpStoreAddon = removeSelectedAddon;
window.upsellAddPopUpMainProduct = addPopUpMainProduct;
window.upsellAddPopUpSelectProducts = addPopUpSelectProducts;
window.upsellAddPopUpToggleItem = togglePopUpAddonItem;
window.upsellIsShowMainProduct = isShowMainProduct;
window.upsellCombineWithSelectedAddons = combineWithSelectedAddons;
window.upsellSetPopUpItemState = setPopUpItemState;
window.upsellGetSelectedPopUpAddons = getSelectedPopUpAddons;

export {
  closePopUp,
  showPopUp,
  togglePopUpAddonItem,
  addPopUpMainProduct,
  addPopUpSelectProducts,
  setThemeInstance,
  getThemeInstance,
  isShowMainProduct
};
