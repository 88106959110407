import { getAppProps, getLocale } from '../utils';

const TRANSLATE_IDS = {
  popUpAddItemText: 1,
  popUpRemoveItemText: 2,
  popUpNoIamGoodText: 3,
  popUpContinueShoppingText: 4,
  popUpAddToCartText: 5,
  popUpSaveText: 6,
  popUpContinueText: 7,
  popUpCheckoutText: 8,
  mandatoryAddOnText: 9,
  discountOfferAddBundleText: 10,
  popUpHeaderText: 11,
  popUpDescText: 12,
  headerText: 13,
  addToCartText: 14,
  removeFromCartText: 15,
  seeDetailsText: 16,
  availableWithText: 17
};

const TRANSLATE_DATA = {
  [TRANSLATE_IDS.popUpAddItemText]: {
    textId: 'popUpAddItemText',
    defaultText: 'Add'
  },
  [TRANSLATE_IDS.popUpRemoveItemText]: {
    textId: 'popUpRemoveItemText',
    defaultText: 'Remove'
  },
  [TRANSLATE_IDS.popUpNoIamGoodText]: {
    textId: 'popUpNoIamGoodText',
    defaultText: 'No, I’m good'
  },
  [TRANSLATE_IDS.popUpContinueShoppingText]: {
    textId: 'popUpContinueShoppingText',
    defaultText: 'Continue Shopping'
  },
  [TRANSLATE_IDS.popUpAddToCartText]: {
    textId: 'popUpAddToCartText',
    defaultText: 'Add to cart'
  },
  [TRANSLATE_IDS.popUpSaveText]: {
    textId: 'popUpSaveText',
    defaultText: 'Save'
  },
  [TRANSLATE_IDS.popUpContinueText]: {
    textId: 'popUpContinueText',
    defaultText: 'Checkout'
  },
  [TRANSLATE_IDS.popUpCheckoutText]: {
    textId: 'popUpCheckoutText',
    defaultText: 'Check out'
  },
  [TRANSLATE_IDS.mandatoryAddOnText]: {
    textId: 'mandatoryAddOnText',
    defaultText: 'You must choose add-on before adding to cart'
  },
  [TRANSLATE_IDS.discountOfferAddBundleText]: {
    textId: 'discountOfferAddBundleText',
    defaultText: 'Add Bundle to Cart | Save'
  },
  [TRANSLATE_IDS.popUpHeaderText]: {
    textId: 'popUpHeaderText',
    defaultText: 'Hang on!'
  },
  [TRANSLATE_IDS.popUpDescText]: {
    textId: 'popUpDescText',
    defaultText: 'We have a special offer for you:'
  },
  [TRANSLATE_IDS.headerText]: {
    textId: 'headerText',
    defaultText: ''
  },
  [TRANSLATE_IDS.addToCartText]: {
    textId: 'addToCartText',
    defaultText: 'Add to cart'
  },
  [TRANSLATE_IDS.removeFromCartText]: {
    textId: 'removeFromCartText',
    defaultText: 'Remove'
  },
  [TRANSLATE_IDS.seeDetailsText]: {
    textId: 'seeDetailsText',
    defaultText: '(See details)'
  },
  [TRANSLATE_IDS.availableWithText]: {
    textId: 'availableWithText',
    defaultText: 'Available with'
  }
};

function isTextVisible(options, localeTextId) {
  return options[localeTextId] && options[localeTextId].trim().length > 0;
}

function translateText(id) {
  if (!id) {
    return '';
  }

  const data = TRANSLATE_DATA[id];

  if (!data) {
    return '';
  }

  const { options } = getAppProps();
  const locale = getLocale();

  const { textId, defaultText } = data;
  let localeTextId = textId;

  if (locale) {
    localeTextId = `${textId}_${locale}`;
  }

  let isVisible = false;

  // Locale
  if (isTextVisible(options, localeTextId) || isTextVisible(options, textId)) {
    isVisible = true;
  }

  return isVisible ? options[localeTextId] || options[textId] : defaultText;
}

export { TRANSLATE_IDS, translateText };
