import {
  combineWithSelectedAddons,
  getAppProps,
  getAttributesForm,
  getMainProductQuantity,
  getMainProductVariant,
  getNoteText,
  setMouseLoading
} from '../../utils';
import { getCartItems } from '../../api/common';
import { discountApi } from '../../api/discount';
import { ENABLED_DISCOUNTS } from '../../constants';
import { redirectCheckoutPage } from '../../utils/redirect';
import { showDefaultError } from '../../utils/error';
import { PAGE } from '../../domain';
import { getForm } from '../../render/common/utils';

async function callDiscountAPI() {
  // Init animation due to get cart items
  setMouseLoading(true);

  const cartItems = await getCartItems();
  const items = [];

  // Prepare items
  cartItems.forEach(item => {
    items.push({
      product_id: item.product_id,
      variant_id: item.variant_id,
      id: item.id,
      quantity: item.quantity,
      type: 'addon',
      properties: item.properties
    });
  });

  discountApi(items);
}

export function callNextStep() {
  if (ENABLED_DISCOUNTS) {
    callDiscountAPI();
  } else {
    redirectCheckoutPage();
  }
}

export function callEmptyNextStep() {
  // Init animation due to get cart items
  setMouseLoading(true);

  // Add main item
  const items = [];

  const quantity = getMainProductQuantity();
  const variantId = getMainProductVariant();

  items.push({
    id: parseInt(variantId),
    quantity
  });

  // Add checkbox-selected addons
  combineWithSelectedAddons(items);

  fetch('/cart/add.js', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({
      items
    })
  })
    .then(() => {
      // Call all next steps
      callNextStep();
    })
    .catch(err => {
      const { options } = getAppProps();

      showDefaultError('Error occurred!', options);
    });
}

export function getAdditionalAttributes() {
  const text = getNoteText();
  const page = PAGE.Cart;
  const { options } = getAppProps();
  const cartForm = getForm(page, options);
  const attr = getAttributesForm(jUpsell(cartForm));

  const props = {};
  if (
    Object.keys(attr).length > 0 ||
    (typeof text === 'string' && text.length > 0)
  ) {
    if (text) {
      props.note = text;
    }

    if (attr) {
      props.attributes = attr;
    }
  }

  return props;
}
