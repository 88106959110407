import { setMouseLoading } from '../utils';
import axios from 'axios';
import { redirectCheckoutPage } from '../utils/redirect';
import { getShopName } from '../utils/shopify';
import { getItemDiscount, getItemFromAnyAddon } from '../utils/item';
import { getAdditionalAttributes } from '../pages/productPageInjector/common';
import {
  getDiscountOfferInfo,
  isAllDiscountOfferItemsExists
} from '../common/discountOffer';

export function discountApi(items) {
  const additionalAttributes = getAdditionalAttributes();
  const shopname = getShopName(true);
  const discounts = [];
  const nonDiscountItems = [];

  items.forEach(v => {
    const {
      product_id,
      variant_id,
      id,
      quantity,
      type = 'addon',
      properties = {}
    } = v;

    const discount = getItemDiscount(v);

    if (discount > 0) {
      const info = getItemFromAnyAddon(v);

      const send = {
        id,
        quantity,
        type,
        productId: product_id.toString(),
        variantId: variant_id.toString(),
        properties
      };

      if (info) {
        send.target = {
          productId: info.productId,
          variantId: info.variantId
        };
      }

      discounts.push(send);
    } else {
      nonDiscountItems.push({
        id,
        quantity,
        productId: product_id.toString(),
        variantId: variant_id.toString(),
        properties
      });
    }
  });

  if (discounts.length > 0) {
    setMouseLoading(true);
    axios
      .post(
        DISCOUNT_API_URL,
        {
          items: discounts,
          nonDiscountItems,
          shop: shopname,
          additionalAttributes,
          discountOffer: isAllDiscountOfferItemsExists()
            ? getDiscountOfferInfo()
            : null
        },
        { timeout: 10 * 1000 } // timeout 10 secs -> catch block.
      )
      .then(res => {
        setMouseLoading(false);

        const { error, errCode, result } = res.data;
        if (error) {
          console.log('err', errCode);
          // Redirect without discount.
          redirectCheckoutPage();
          return;
        }

        // Redirect to new our url checkout
        window.location.href = result.url;
      })
      .catch(err => {
        setMouseLoading(false);

        console.log('err', err);
        // Redirect without discount.
        redirectCheckoutPage();
      });
  } else {
    setMouseLoading(false);

    redirectCheckoutPage();
  }
}
