import { TRANSLATE_IDS, translateText } from '../../utils/translate';
import { showWarningTip } from '../../utils/showTip';
import { getAppProps } from '../../utils';
import { getCartItemsDetails } from '../../currentCart';
import { selectedAddOns } from '../../cartActionHandler';
import { createAddOnRecord } from '../../addOnsUtil';
import { getTargetProductId } from '../../utils/item';

function addFrom({
  addOnsDb,
  recordId,
  options,
  cartItems,
  selectedItems,
  items,
  result
}) {
  if (addOnsDb[recordId]) {
    const iterCollection = addOnsDb[recordId];

    const combineRecords = {};
    for (const key in iterCollection) {
      const parsed = createAddOnRecord(iterCollection, key);
      const variantId = parsed.ids[0];
      if (options.groupVariants) {
        const handle = parsed.handle;
        if (!combineRecords[handle]) {
          combineRecords[handle] = parsed;
        } else {
          combineRecords[handle].ids.push(variantId);
        }
      } else {
        combineRecords[variantId] = parsed;
      }
    }

    for (const iter in combineRecords) {
      const record = combineRecords[iter];
      const { mandatory, ids } = record;
      if (mandatory) {
        if (ids.length === 1) {
          const id = ids[0];
          // check for only one
          if (!cartItems.find(v => v.id === id) && !selectedItems[id]) {
            const item = items.find(v => v.id === id);
            if (item) {
              result.push({
                title: item.title
              });
            }
          }
          // check for various
        } else {
          let found = false;

          // search in cart
          for (let id of ids) {
            // Exists in cart or selected
            if (cartItems.find(v => v.id === id) || selectedItems[id]) {
              found = true;
            }
          }

          if (!found) {
            const item = items.find(v => {
              if (
                v.variantOptions &&
                v.variantOptions.find(iter => iter.id === ids[0])
              ) {
                return true;
              }
              return false;
            });
            if (item) {
              result.push({
                title: item.title
              });
            }
          }
        }
      }
    }
  }
}

export function getMandatoryAddons() {
  const { addOnsDb, options } = getAppProps();
  let result = [];
  const productId = getTargetProductId();
  const recordId = window.upsellGetMainProductRecordId();
  const cartItems = getCartItemsDetails();
  const items = window.upsell.addons;
  const selectedItems = selectedAddOns();

  // Add from product
  addFrom({
    addOnsDb,
    recordId: productId,
    options,
    cartItems,
    selectedItems,
    items,
    result
  });

  // Add from variant
  addFrom({
    addOnsDb,
    recordId,
    options,
    cartItems,
    selectedItems,
    items,
    result
  });

  // get by unique
  const exists = {};
  const data = [];
  for (const iter of result) {
    if (!exists[iter.title]) {
      exists[iter.title] = true;
      data.push(iter);
    }
  }

  return data;
}

export function isMandatoryExists() {
  const manadatoryAddons = getMandatoryAddons();
  if (manadatoryAddons && manadatoryAddons.length > 0) {
    return true;
  }

  return false;
}

export function showMandatoryInfo() {
  const manadatoryAddons = getMandatoryAddons();
  const { options } = getAppProps();

  const headerText = translateText(TRANSLATE_IDS.mandatoryAddOnText);
  showWarningTip(
    `
        ${headerText}:
        ${manadatoryAddons.map(v => `<br>${v.title}`)}
      `,
    options
  );
}
