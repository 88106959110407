import { addOnCheckboxActionHandler } from './cartActionHandler';
import { PAGE, LAYOUT_TYPE } from './domain';
import { variantSelectionHandler } from './variantSelectionHandler';
import createSlider from './slider';
import renderPlaceholder from './render/components/renderPlaceholder';
import { getForm, getSubmitButton, removeUpsell } from './render/common/utils';
import CssClasses from './template/common/cssClasses';
import buildaddOnsHtml from './render/build/buildAddonsHtml';
import { getAppProps } from './utils';
import { setPopupsLoaded } from './template/components/popUp/common';

let lastRenderedAddons = null;

function compareLastRendered(a1, a2) {
  return (
    a1.length === a2.length && a1.every((o, idx) => o.ids[0] === a2[idx].ids[0])
  );
}

export default function initRenderer(addOns, popups, page, handle) {
  if (lastRenderedAddons && compareLastRendered(lastRenderedAddons, addOns)) {
    return;
  }

  // show anim placeholder
  if (addOns.length > 0) {
    renderPlaceholder(page, handle);
  }

  const { options } = getAppProps();

  const cartForm = getForm(page, options);
  if (!cartForm) {
    removeUpsell();
    return;
  }

  const addToCartButton = getSubmitButton(options, cartForm, page);
  const isAriaDisabled =
    addToCartButton &&
    addToCartButton.getAttribute('aria-disabled') &&
    addToCartButton.getAttribute('aria-disabled') === 'true';

  let hasDisabled =
    !addToCartButton ||
    ((addToCartButton.disabled || isAriaDisabled) &&
      !options.displayOnDisabledCartButton);

  // if we changed variant immediately, prevent stop rendering (some themes disabled button)
  if (window.upsell.variantChanged) {
    hasDisabled = false;
  }

  if (hasDisabled) {
    removeUpsell();
  } else {
    if (
      page === PAGE.Product &&
      (options.addOnType === 'checkbox' ||
        options.addOnType === 'dropdown' ||
        options.quantitySelect)
    ) {
      addOnCheckboxActionHandler(cartForm, addToCartButton, options);
    }
    if (options.groupVariants) {
      variantSelectionHandler();
    }

    const hasSlider =
      (page === PAGE.Product && options.productLayout === LAYOUT_TYPE.Slider) ||
      (page === PAGE.Cart && options.cartLayout === LAYOUT_TYPE.Slider);
    buildaddOnsHtml(addToCartButton, addOns, popups, page, function(
      addOnsHtml
    ) {
      lastRenderedAddons = addOns;
      setPopupsLoaded(true);
      const containers = document.querySelectorAll(
        `.${CssClasses.addOnContainer}`
      );
      containers.forEach(addOnContainer => {
        addOnContainer.innerHTML = '';
        if (hasSlider) {
          addOnContainer.className = `${addOnContainer.className} ${CssClasses.invisible}`;
        }
        addOnContainer.insertAdjacentHTML('afterbegin', addOnsHtml);
        if (hasSlider) {
          createSlider(function() {
            new Flickity(addOnContainer, {
              pageDots: false,
              contain: true,
              freeScroll: true,
              cellAlign: 'left'
            });
            addOnContainer.className = addOnContainer.className.replace(
              CssClasses.invisible,
              ''
            );
          });
        }
      });
    });
  }
}

export { renderPlaceholder, removeUpsell };
