import { CssClasses, STYLE_TOKENS, TOKENS } from '../../templates';
import { TRANSLATE_IDS, translateText } from '../../utils/translate';

export default function buildContainer(containerTemplate) {
  const headerText = translateText(TRANSLATE_IDS.headerText);
  const headerTextVisible = headerText !== '';

  return containerTemplate
    .replace(TOKENS.headerText, headerText)
    .replace(
      STYLE_TOKENS.header,
      headerTextVisible && headerText ? '' : CssClasses.hidden
    );
}
