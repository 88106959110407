import { PAGE } from '../../../domain';
import {
  getBuyItNowButton,
  getForm,
  getImageUrl,
  getPrices,
  getSubmitButton,
  getUrl
} from '../../../render/common/utils';
import {
  getAppProps,
  getButtonStyles,
  getCartPageURL,
  getOtherAddonDetails,
  getSlug,
  isUltimateSubscription,
  setMouseLoading
} from '../../../utils';
import {
  fetchProductDetail,
  prepareAddonDetails
} from '../../../getAddOnDetails';
import showError, { showDefaultError } from '../../../utils/error';
import {
  DISCOUNT_OFFER_OPTIONS,
  getAddons,
  getBlocksRenderingTypesData,
  getOptionInfo,
  getOptions,
  getSelectedOfferId,
  isValidOptions,
  prepareItems,
  RENDER_TYPES,
  RENDER_TYPES_IDS
} from '../../../common/discountOffer';
import { styles } from './styles';
import { TRANSLATE_IDS, translateText } from '../../../utils/translate';
import { ANALYTIC_CATEGORIES } from '../../../analytics';
import { updateAnalytics } from '../../../utils/analytics';
import {
  bundleVariantSelectionHandler,
  renderPrice,
  variantBundleDropdown
} from './variantSelection';

export const DISCOUNT_OFFER_BLOCK_ID =
  'upsell-theme-discount-offer-app-block-custom';

const CONTAINER_GROUP_ID = 'discountOfferBlockMainRender';

function injectStyles() {
  document.querySelector('head').insertAdjacentHTML('beforeend', styles);
}

function renderAddon(args) {
  const { optionId, addOnDetails, type, addOnRecord } = args;
  const { title: productTitle } = addOnDetails;

  const { options } = getAppProps();
  const info = getOptionInfo(optionId, type);
  const image = getImageUrl(options, addOnDetails, 0);
  const priceDetails = getPrices(options, addOnDetails, 0);

  const { priceNumberText, finalPrice } = getOtherAddonDetails({
    priceDetails,
    addOnRecord: {
      ...addOnRecord,
      discount: info.discount
    }
  });

  let title = options.discountOfferLinkTitle
    ? `<a href="${getUrl(addOnDetails, 0)}"   ${
        options.linkInNewPage ? 'target="_blank"' : ''
      }>${productTitle}</a>`
    : productTitle;
  const handle = addOnRecord.handle;

  return `
  <div class="discountOfferBlockRow" id="discount-offer-block-row-${optionId}${type}${handle}">
    <img class="discountOffferBlockRow__Image" src=${image} alt="icon">
    <div class="discountOfferBlockRow__Title">
        ${title}
        ${variantBundleDropdown(
          optionId,
          type,
          handle,
          addOnDetails.variantOptions,
          addOnDetails.variantOptionsLabel,
          addOnDetails
        )}
    </div>
    <div class="discountOfferBlockRow__Price">
        ${renderPrice(priceNumberText, finalPrice)}
    </div>
  </div>
  `;
}

async function renderAddons(optionId, addons, type) {
  const options = getAppProps();
  window.upsell[type].info[optionId] = {};

  let id = 0;
  const promisesList = [];
  const htmls = {};

  const items = prepareItems({ ...addons });

  for (const handle in items) {
    const processAddon = orderId => {
      const promise = new Promise(resolve => {
        fetchProductDetail(
          getSlug(handle),
          function(result) {
            const product = { ...result.product };
            const [_, variantId] = handle.split('|');
            if (variantId) {
              product.variant_id = parseInt(variantId);
            }
            const addOnRecord = items[handle];
            const addOnDetails = prepareAddonDetails({
              options,
              result: {
                product: result.product
              },
              addOnRecord
            });
            const html = renderAddon({
              optionId,
              addOnDetails,
              type,
              addOnRecord
            });
            htmls[orderId] = html;
            window.upsell[type].info[optionId][handle] = product;
            resolve(html);
          },
          () => {
            console.log('err');
            resolve(false);
          }
        );
      });

      promisesList.push(promise);
    };

    id++;
    processAddon(id);
  }

  await Promise.all(promisesList);
  const html = Object.values(htmls).join('');

  return `
    <div class="discountOfferBlock__Addons">
        ${html}
    </div>
  `;
}

async function renderBlock(data) {
  const { id, addons, text, type } = data;
  const html = await renderAddons(id, addons, type);

  return `
    <div class="discountOfferMain">
        <div class="discountOfferContainer">
            <div class="discountOfferContainer__Option">
                <div class="discountOfferContainer__Option_Title">
                    ${text}
                </div>
                ${html}   
            </div>  
        </div>  
    </div>
  `;
}

async function renderWidget(type, data, key) {
  const blocksHTML = [];
  const iter = data[key];
  const options = getOptions(iter);
  const optionId = Object.keys(options)[0];
  if (!optionId){
    return '';
  }
  const wrapOptionId = `${key}_${optionId}`;
  const option = options[optionId];
  if (!option){
    return '';
  }
  const optionData = option[DISCOUNT_OFFER_OPTIONS];
  if (!optionData){
    return '';
  }
  const addons = getAddons(option);
  if (Object.keys(addons).length !== 0) {
    window.upsell[type].options[wrapOptionId] = optionData;
    const html = await renderBlock({
      id: wrapOptionId,
      text: optionData.text,
      addons,
      type
    });
    blocksHTML.push(html);
  }

  if (blocksHTML.length === 0) {
    return '';
  }

  return `
    <div class="discountOffer__Widget">
      <div class="discountOfferBlock__Main">
          ${blocksHTML.join('')}
          ${renderButton(optionData, type, wrapOptionId)}
      </div>
    </div>
  `;
}

async function renderBlocks() {
  const { All, Collection, Catalog } = getBlocksRenderingTypesData();

  const blocksHTML = [];

  for (const v in All) {
    const html = await renderWidget(RENDER_TYPES.All, All, v);
    blocksHTML.push(html);
  }

  for (const v in Collection) {
    const html = await renderWidget(RENDER_TYPES.Collection, Collection, v);
    blocksHTML.push(html);
  }

  for (const v in Catalog) {
    const html = await renderWidget(RENDER_TYPES.Catalog, Catalog, v);
    blocksHTML.push(html);
  }

  const filteredHTMLS = blocksHTML.filter(v => v !== '');

  return `
    ${filteredHTMLS.join('')}
  `;
}

function renderButton(offerInfo, type, id) {
  let styles = '';
  const btn = getBuyItNowButton();
  if (btn) {
    styles = getButtonStyles(btn);
  }

  return `
    <div class="discountOfferBlockContainer">
        <div class="discountOfferBlockContainer__Button" 
            onclick="window.upsellAddDiscountOffer('${type}','${id}')"
            style="${styles || ''}">
            <div class="discountOfferBlockContainer__Text">
                ${getButtonText(offerInfo)}
            </div>
        </div>
    </div>
  `;
}

async function renderHTML() {
  const blocks = await renderBlocks();

  const containers = document.querySelectorAll(`.${CONTAINER_GROUP_ID}`);
  containers.forEach(addOnContainer => {
    addOnContainer.innerHTML = '';
    addOnContainer.className = '';
    addOnContainer.insertAdjacentHTML(
      'afterbegin',
      `
    <div class="discountOffer__Wrap">
        ${blocks}
    </div>
    `
    );
  });

  for (const key in RENDER_TYPES) {
    const val = RENDER_TYPES[key];
    window.upsell[val].init = true;
  }
}

function getButtonText(offerInfo) {
  const text = translateText(TRANSLATE_IDS.discountOfferAddBundleText);

  return `${text} -${offerInfo.discount}%`;
}

function getSelectedItems(type) {
  const offerId = getSelectedOfferId();
  const addOns = window.upsell[type].info[offerId];
  return Object.values(addOns).map(v => ({
    id: v.variant_id || v.variants[0].id,
    quantity: 1
  }));
}

function addDiscountOffer(type, id) {
  // Set offer id
  window.upsellDiscountOfferId = id;

  const { options } = getAppProps();

  // Init animation due to get cart items
  setMouseLoading(true);

  // get items
  const items = getSelectedItems(type);

  fetch('/cart/add.js', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    body: JSON.stringify({ items })
  })
    .then(res => res.json())
    .then(json => {
      setMouseLoading(false);

      if (json.status && json.status !== 200) {
        showError(json, options, json.description, 'products');
        return;
      }

      // Call all next steps
      const offerData = `${RENDER_TYPES_IDS[type]}_${id}`;
      window.location.href = `${getCartPageURL()}?custom_offer=${offerData}`;
    })
    .catch(err => {
      console.log('err', err);
      setMouseLoading(false);
      showDefaultError('Error occurred!', options);
    });

  // Update analytics stuff
  updateAnalytics(items, ANALYTIC_CATEGORIES.DiscountOffer);
}

function loading() {
  return `
    <div class="${CONTAINER_GROUP_ID}" class="animated-background">
    </div>
  `;
}

function tryRenderInKnownContainer(loading) {
  const appBlocks = document.querySelectorAll(`.${DISCOUNT_OFFER_BLOCK_ID}`);
  if (appBlocks.length > 0) {
    appBlocks.forEach(v => {
      v.insertAdjacentHTML('afterbegin', loading);
    });
    return true;
  }
  return false;
}

function removeDiscountOffer() {
  const element = document.querySelector('.discountOffer__Wrap');
  if (element) {
    element.remove();
  }
}

export default function renderDiscountOffer() {
  const { options } = getAppProps();

  if (!isUltimateSubscription()) {
    return false;
  }

  if (!isValidOptions()) {
    return false;
  }

  for (const key in RENDER_TYPES) {
    const val = RENDER_TYPES[key];
    window.upsell[val] = {
      init: false,
      info: {},
      options: {}
    };
  }

  removeDiscountOffer();
  injectStyles();

  const isRendered = tryRenderInKnownContainer(loading());
  if (!isRendered) {
    const page = PAGE.Product;
    const cartForm = getForm(page, options);

    if (!cartForm) return false;

    const position = options.discountOfferPosition || 'end';
    const loadingHTML = loading();

    if (position === 'end') {
      cartForm.insertAdjacentHTML('afterend', loadingHTML);
    } else if (position === 'before') {
      cartForm.insertAdjacentHTML('beforebegin', loadingHTML);
    } else {
      const cartForm = getForm(page, options);

      let attachedTo = null;
      const buyItNow = getBuyItNowButton();
      if (buyItNow) {
        attachedTo = buyItNow;
      }
      const addToCartButton = getSubmitButton(options, cartForm, page);
      if (!attachedTo) {
        attachedTo = addToCartButton;
      }

      if (!attachedTo) return false;

      if (position === 'after') {
        attachedTo.insertAdjacentHTML('afterend', loadingHTML);
      } else {
        attachedTo.insertAdjacentHTML('beforebegin', loadingHTML);
      }
    }
  }

  renderHTML();
  bundleVariantSelectionHandler();
  return true;
}

window.upsellAddDiscountOffer = addDiscountOffer;
