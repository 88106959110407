export function getCartItems() {
  return new Promise(resolve => {
    jUpsell
      .getJSON('/cart.js', function(cart) {
        resolve(cart.items);
      })
      .fail(() => {
        resolve([]);
      });
  });
}

export async function updateCartProperties(attr) {
  return fetch('/cart/update.js', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(attr)
  });
}

export function ajaxQueryAddToCart({ isRemove, qty, variantId }) {
  let item = {
    quantity: isRemove ? 0 : qty,
    id: variantId
  };

  let data = {};

  if (isRemove) {
    data = {
      ...item,
      sections: 'header'
    };
  } else {
    data = {
      items: [item],
      sections: 'header'
    };
  }

  return jUpsell.ajax({
    url: isRemove ? '/cart/change.js' : '/cart/add.js',
    dataType: 'json',
    data,
    type: 'POST',
    xhrFields: {
      withCredentials: true
    }
  });
}
