import { getAppProps, getGrouppedVariants, isCartPage } from '../utils';
import { createAddOnRecord } from '../addOnsUtil';
import { getHookCheckoutItems } from '../pages/cartPageInjector/utils';
import { prepareAddonDetails } from '../getAddOnDetails';
import { getTargetCollection, getTargetProductId } from '../utils/item';
import { getCollectionIds } from '../collectionAddOns';
import { PAGE } from '../domain';

export const DISCOUNT_OFFER_OPTIONS = '__options__';
export const RENDER_TYPES = {
  All: 'discountOffers',
  Collection: 'discountOffersCollection',
  Catalog: 'discountOffersCatalog'
};

export const RENDER_TYPES_IDS = {
  [RENDER_TYPES.All]: 1,
  [RENDER_TYPES.Collection]: 2,
  [RENDER_TYPES.Catalog]: 3
};

export const RENDER_TYPES_BY_ID = {
  All: RENDER_TYPES_IDS[RENDER_TYPES.All],
  Collection: RENDER_TYPES_IDS[RENDER_TYPES.Collection],
  Catalog: RENDER_TYPES_IDS[RENDER_TYPES.Catalog]
};

export function getDiscountOfferInfo() {
  return new URL(window.location.href).searchParams.get('custom_offer');
}

export function getDiscountOfferInfoValues() {
  const info = getDiscountOfferInfo();
  if (!info) {
    return null;
  }
  const splitted = info.split('_');
  if (splitted.length < 3) {
    return null;
  }
  return {
    typeId: parseInt(splitted[0]),
    productId: splitted[1],
    optionId: splitted[2]
  };
}

export function getCurrentDiscountOfferBundle() {
  const {
    discount_offer,
    discount_offer_collection,
    discount_offer_catalog
  } = getAppProps();

  const info = getDiscountOfferInfoValues();
  if (info) {
    if (info.typeId === RENDER_TYPES_BY_ID.All) {
      return discount_offer;
    }

    if (info.typeId === RENDER_TYPES_BY_ID.Collection) {
      return discount_offer_collection;
    }

    if (info.typeId === RENDER_TYPES_BY_ID.Catalog) {
      return discount_offer_catalog;
    }
  }
  return {};
}

export function isAllDiscountOfferItemsExists() {
  const info = getDiscountOfferInfoValues();
  if (!info) {
    return false;
  }

  const discount_offer = getCurrentDiscountOfferBundle();
  const product = discount_offer[info.productId];
  if (product) {
    const option = product[info.optionId];
    if (option) {
      const addOns = getAddons(option);

      // Check if all items exists
      if (isCartPage()) {
        const items = getHookCheckoutItems();
        const groupped = getGrouppedVariants(addOns);
        for (const key in groupped) {
          const parsed = groupped[key];
          if (!items.find(v => parsed.ids.indexOf(v.variant_id) !== -1)) {
            return false;
          }
        }
        return true;
      }
    }
  }
  return false;
}

export function getItemDiscountForOffer(item) {
  const discount_offer = getCurrentDiscountOfferBundle();

  const info = getDiscountOfferInfoValues();
  if (!info) {
    return 0;
  }

  const product = discount_offer[info.productId];
  if (product) {
    const option = product[info.optionId];
    if (option) {
      const record = readOptionRecord(option[DISCOUNT_OFFER_OPTIONS]);
      const discount = record.discount;
      if (discount > 0) {
        const { variant_id } = item;

        const addOns = getAddons(option);
        let isValid = true;

        // Check if all items exists
        if (isCartPage()) {
          const items = getHookCheckoutItems();
          const groupped = getGrouppedVariants(addOns);
          for (const key in groupped) {
            const parsed = groupped[key];
            if (!items.find(v => parsed.ids.indexOf(v.variant_id) !== -1)) {
              isValid = false;
              break;
            }
          }
        }

        if (isValid) {
          for (const key in addOns) {
            const parsed = createAddOnRecord(addOns, key);
            if (parsed.ids.includes(variant_id)) {
              return discount;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function getBlocksRenderingTypesData() {
  const { discount_offer, discount_offer_catalog } = getAppProps();

  const targetProductId = getTargetProductId();
  const targetHandleId = getTargetCollection();
  const [collection, targetCollectionId] = getCurrentCollection();

  const All = {};

  if (discount_offer[targetProductId]) {
    All[targetProductId] = discount_offer[targetProductId];
  }

  if (discount_offer[targetHandleId]) {
    All[targetHandleId] = discount_offer[targetHandleId];
  }

  const Collection = {};

  if (collection) {
    Collection[targetCollectionId] = collection;
  }

  const Catalog = {};

  if (discount_offer_catalog && discount_offer_catalog.all) {
    Catalog['all'] = discount_offer_catalog.all;
  }

  return {
    All,
    Collection,
    Catalog
  };
}

export function isValidTypeOption(offerSettings) {
  // fix for 'empty' from firebase
  if (!offerSettings) {
    return false;
  }

  const options = getOptions(offerSettings);

  for (const optionId in options) {
    const option = options[optionId];
    const addons = getAddons(option);
    if (Object.keys(addons).length >= 2) {
      return true;
    }
  }

  return false;
}

export function isValidOptions() {
  const { All, Collection, Catalog } = getBlocksRenderingTypesData();

  const checks = [
    Object.values(All).some(v => isValidTypeOption(v)),
    Object.values(Collection).some(v => isValidTypeOption(v)),
    Object.values(Catalog).some(v => isValidTypeOption(v))
  ];

  return checks.some(v => v === true);
}

export function getOptions(settings) {
  const records = {};
  const addonsIds = Object.keys(settings)
    // exclude special property options
    .filter(value => value !== DISCOUNT_OFFER_OPTIONS);

  addonsIds.forEach(offerId => {
    records[offerId] = {};
    Object.keys(settings[offerId]).forEach(productHandle => {
      if (productHandle === DISCOUNT_OFFER_OPTIONS) {
        records[offerId][productHandle] = readOptionRecord(
          settings[offerId][productHandle]
        );
      } else {
        records[offerId][productHandle] = createAddOnRecord(
          settings[offerId],
          productHandle
        );
      }
    });
  });

  return records;
}

export function getAddons(option) {
  const records = {};
  const addonsIds = Object.keys(option)
    // exclude special property options
    .filter(value => value !== DISCOUNT_OFFER_OPTIONS);

  addonsIds.forEach(addOnId => {
    records[addOnId] = option[addOnId];
  });

  return records;
}

export function readOptionRecord(record) {
  const info = {
    discount: 0
  };
  const splitted = record.split('|');
  const len = splitted.length;

  if (len > 0) {
    info.text = splitted[0];
  }

  if (len > 1) {
    info.discount = parseInt(splitted[1]);
  }

  return info;
}

export function getSelectedOfferId() {
  return window.upsellDiscountOfferId;
}

export function getDiscountOfferProduct(addOnId, type) {
  const offerId = getSelectedOfferId();
  if (!offerId) {
    return null;
  }
  const addOns = window.upsell[type].info[offerId];
  if (!addOns) {
    return null;
  }
  const variantId = parseInt(addOnId);
  const { options } = getAppProps();
  for (const handle in addOns) {
    const result = addOns[handle];

    const details = prepareAddonDetails({
      options,
      result: {
        product: result
      },
      addOnRecord: {
        ids: [variantId]
      } // we don't use order
    });

    if (details.id === variantId) {
      return details;
    }
  }

  return null;
}

export function getCurrentCollection() {
  const { discount_offer_collection } = getAppProps();

  let targetCollectionId = null;
  let collection = null;
  const collectionAddOns = getCollectionIds(
    PAGE.Product,
    window.upsell.product.details.id
  );
  if (collectionAddOns) {
    // take last collection
    collectionAddOns.forEach(collectionId => {
      if (discount_offer_collection[collectionId]) {
        collection = discount_offer_collection[collectionId];
        targetCollectionId = collectionId;
      }
    });
  }
  return [collection, targetCollectionId];
}

export function prepareItems(items) {
  const { options } = getAppProps();

  if (options.groupVariants) {
    const obj = {};
    for (const key in items) {
      const handle = items[key].handle;
      if (!obj[handle]) {
        obj[handle] = items[key];
      } else {
        obj[handle].ids.push(items[key].ids[0]);
      }
    }
    return obj;
  }

  return items;
}

export function getOptionInfo(optionId, type) {
  return window.upsell[type].options[optionId];
}
