import { getPopUpData } from './utils';
import { initPopUp } from '../../template/components/popUp/index';
import { getAppProps } from '../../utils';
import { hookBuyItNow } from './buyItNow';
import { CLICK_HOOK_ATTR_ADD_TO_CART, getForm, getSubmitButton } from '../../render/common/utils';
import { PAGE } from '../../domain';
import Wrapper from '../../template/components/popUp/wrapper';
import hookInstall from '../../utils/hookInstall';
import { initCartDrawer } from './cartDrawer';
import { callNextStep } from './common';
import { isMandatoryExists, showMandatoryInfo } from '../../template/components/mandatory';
import { sendAddToCartAnalytics } from '../../analytics';

const attributeSetting = 'upsell-hooked-add-to-cart-btn';

function initOtherHook() {
  if (document.querySelector(`[${attributeSetting}="true"]`)) {
    return false;
  }

  const { options } = getAppProps();

  const page = PAGE.Product;
  const cartForm = getForm(page, options);
  if (!cartForm) {
    return false;
  }

  const submitEl = getSubmitButton(options, cartForm, page);
  if (!submitEl) {
    return false;
  }

  const wrap = new Wrapper({
    useCheckout: true,
    useContinue: true
  });

  wrap.onAddToCart = () => {
    callNextStep();
  };

  // Override event
  let shownOnce = false;

  // Replace
  let btnReplace = submitEl;
  if (options.overrideSubmitButton) {
    jUpsell(submitEl).replaceWith(jUpsell(submitEl).clone());

    btnReplace = getSubmitButton(options, cartForm, page);
  }

  btnReplace.setAttribute(attributeSetting, 'true');

  btnReplace.addEventListener('click', e => {
    if (btnReplace.getAttribute(CLICK_HOOK_ATTR_ADD_TO_CART) === 'true'){
      btnReplace.setAttribute(CLICK_HOOK_ATTR_ADD_TO_CART, 'false');
      return;
    }

    // Disable addToCart click if we don't add some addons
    if (isMandatoryExists()) {
      e.preventDefault();
      e.stopImmediatePropagation();
      showMandatoryInfo();
      return;
    }

    if (shownOnce) {
      // Send analytics
      sendAddToCartAnalytics();
      return;
    }

    const variants = getPopUpData();

    if (variants !== null) {
      shownOnce = true;
      initPopUp(wrap);
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }

    // Send analytics
    sendAddToCartAnalytics();
  });

  return true;
}

async function setupShopifyAddToCartHook() {
  hookInstall(initOtherHook);
  hookInstall(initCartDrawer);
  hookInstall(hookBuyItNow);
}

export default setupShopifyAddToCartHook;
