const STYLE_TOKENS = {
  fontColor: '{{fontColor}}',
  fontSize: '{{fontSize}}',
  borderWidth: '{{borderWidth}}',
  borderColor: '{{borderColor}}',
  header: '{{headerStyle}}',
  fontWeight: '{{fontWeight}}',
  customCss: '{{customCss}}',
  customPopupCss: '{{customPopupCss}}',
  headerColor: '{{headerColor}}',
  headerFontSize: '{{headerFontSize}}',
  imageHeight: '{{imageHeight}}',
  imageWidth: '{{imageWidth}}',
  buttonHeight: '{{buttonHeight}}',
  buttonWidth: '{{buttonWidth}}',
  buttonFontSize: '{{buttonFontSize}}',
  buttonPaddingLeft: '{{buttonPaddingLeft}}',
  buttonPaddingTop: '{{buttonPaddingTop}}',
  buttonPaddingRight: '{{buttonPaddingRight}}',
  buttonPaddingBottom: '{{buttonPaddingBottom}}',
  flexDisplay: '{{flexDisplay}}',
  addOnBoxDisplay: '{{addOnBoxDisplay}}',
  addOnBoxWidth: '{{addOnBoxWidth}}',
  addOnBoxHeight: ' {{addOnBoxHeight}}',
  flexWrap: '{{flexWrap}}',
  flexGrow: '{{flexGrow}}',
  containerWidth: '{{containerWidth}}',
  containerHeight: '{{containerHeight}}',
  descriptionFontSize: '{{descriptionFontSize}}',
  descriptionFontColor: '{{descriptionFontColor}}'
};

export default STYLE_TOKENS;
