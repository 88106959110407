import {
  getAppProps,
  getOtherAddonDetails,
  getPriceDetails,
  safeEncodeURIComponent
} from '../../../utils';
import CssClasses from '../../common/cssClasses';
import Element_Ids from '../../common/elementIds';
import { getOptionInfo } from '../../../common/discountOffer';

export function variantBundleDropdown(
  optionId,
  type,
  productHandle,
  variantOptions,
  label,
  data
) {
  if (variantOptions && variantOptions.length > 0) {
    let options = '';
    const optionsLabel = label ? `${label}:` : '';
    for (let i = 0; i < variantOptions.length; i++) {
      options += `<option value=${variantOptions[i].id}>${variantOptions[i].title}</option>`;
    }
    return `<div class="${CssClasses.variantDropdown} ${
      CssClasses.variantBundleDropdown
    }">
                <label>${optionsLabel}</label>
                <select class="${CssClasses.bundleVariantSelector}" id="${
      Element_Ids.bundleVariantSelector
    }${data.id}" 
                        onchange="upsell.changeBundleVariant('${optionId}',
                                                       '${type}',
                                                       '${productHandle}', 
                                                       '${safeEncodeURIComponent(
                                                         JSON.stringify(
                                                           variantOptions
                                                         )
                                                       )}',
                                                       this.value)">
                        ${options}
                </select>
            </div>`;
  }
  return '';
}

export function renderPrice(priceNumberText, finalPrice) {
  return `
    <div>
        <s>
          ${priceNumberText}
        </s>
      </div>
      <div>
        <b style="color: black">
          ${finalPrice}
        </b>
      </div>
  `;
}

export function bundleVariantSelectionHandler() {
  window.upsell = window.upsell || {};
  window.upsell.changeBundleVariant = function(
    optionId,
    type,
    productHandle,
    variantOptionsJson,
    selectedVariantId
  ) {
    // eslint-disable-next-line no-unused-vars
    const { options } = getAppProps();

    const variantOptions = JSON.parse(decodeURIComponent(variantOptionsJson));
    const variantOption = variantOptions.find(function(variantOption) {
      return variantOption.id === parseInt(selectedVariantId);
    });

    // Root
    const addOnContainer = document.querySelector(
      `#discount-offer-block-row-${optionId}${type}${productHandle}`
    );

    // Url
    const aTags = addOnContainer.querySelectorAll('a');
    for (let i = 0; i < aTags.length; i++) {
      aTags[i].setAttribute('href', variantOption.url);
    }

    // Image
    const imgSrc = options.useThumbnailImage
      ? variantOption.originalSmallImage
      : variantOption.image;
    addOnContainer.querySelector('img').setAttribute('src', imgSrc || '');

    // Price
    const priceDetails = getPriceDetails(
      options,
      variantOption.price,
      variantOption.compareAtPrice
    );

    const info = getOptionInfo(optionId, type);

    const { priceNumberText, finalPrice } = getOtherAddonDetails({
      priceDetails,
      addOnRecord: {
        discount: info.discount
      }
    });

    // Update price element
    const priceBlock = addOnContainer.querySelector(
      '.discountOfferBlockRow__Price'
    );
    if (priceBlock) {
      priceBlock.innerHTML = renderPrice(priceNumberText, finalPrice);
    }

    window.upsell[type].info[optionId][productHandle].variant_id =
      variantOption.id;
  };
}
