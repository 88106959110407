import { disableMouseClick, getCartPageURL } from '../utils';

export function redirectCartPage() {
  // Fix on remove bug if you click on item it causes 400 error, disable any clicks during reloading page
  disableMouseClick();

  window.location.href = getCartPageURL();
}

export function redirectCheckoutPage(args = '') {
  window.location.href = `/checkout${args}`;
}
