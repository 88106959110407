export const styles = `
<style>
  .discountOffer__Wrap {
    padding-top: 20px;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
  }
  
  .discountOffer__Widget {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .discountOffer__Widget:not(:last-child) {
    padding-bottom: 20px;
  }
  
  .discountOffer__Title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    color: rgb(33, 43, 54);
    text-align: center;
  }
  
  .discountOfferBlock__Main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    border: 1px solid #95a5a6;
    border-radius: 4px;
  }
  
  .discountOfferMain {
    width: 100%;
    display: flex;
    min-height: 82px;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    position: relative;
  }
  
  .discountOfferContainer {
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .discountOfferContainer__Option {
    width: 100%;
  }
  
  .discountOfferContainer__Option_Title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .discountOfferBlockContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 10px 10px;
    width: 100%;
  }
  
  .discountOfferBlockContainer__Button {
    background: black;
    color: rgb(255, 255, 255);
    border-radius: 0px;
    padding: 10px;
    width: 100%;
    height: 48px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    user-select: none;
  }
  
  .discountOfferBlockContainer__Text {
    display: -webkit-box;
    margin: 0px auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
  }
  
  .discountOfferBlockRow {
    display: flex;
    padding: 25px 5px 25px;
    width: 100%;
  }
  
  .discountOfferBlock__Addons > div:not(:only-child):not(:last-child) {
      border-bottom: 1px solid #95a5a6;
      position: relative;
  }
  
  .discountOfferBlock__Addons > div:not(:only-child):not(:last-child)::after {
    content: "";
    --color-plus-offer: #95a5a6;
    --plus-offer-b: 5px;
    --plus-offer-w: 30px;
    width: var(--plus-offer-w);
    aspect-ratio: 1;
    border: 7px solid var(--color-plus-offer);
    background: conic-gradient(from 90deg at var(--plus-offer-b) var(--plus-offer-b),var(--color-plus-offer) 90deg,#fff 0) calc(100% + var(--plus-offer-b)/2) calc(100% + var(--plus-offer-b)/2)/ calc(50% + var(--plus-offer-b)) calc(50% + var(--plus-offer-b));
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - var(--plus-offer-w)/2);
    top: calc(100% - var(--plus-offer-w)/2);
  }
  
  .discountOffferBlockRow__Image {
    display: block;
    width: 60px;
    margin-left: 5px;
    border: 1px solid #95a5a6;
    object-fit: contain;
  }
  
  .discountOfferBlockRow__Title {
    max-width: 50%;
    margin-left: 10px;
    font-weight: 700;
    color: #0a0a0a;
  }
  
  .discountOfferBlockRow__Title a:hover, 
  .discountOfferBlockRow__Title a:focus,
  .discountOfferBlockRow__Title a:active,
  .discountOfferBlockRow__Title a {
    text-decoration: none;
    color: inherit;
  }
  
  .discountOfferBlockRow__Price {
    margin-left: auto;
  }
  
  .discountOfferBlock__Addons {
    
  }
  
  .discountOfferDivider {
    border-bottom: 1px solid #95a5a6;
  }
</style>
`;
