import loadScript from './loadScript';
import * as cache from './cache';
import {
  isProductPage,
  isCartPage,
  setAppProps,
  isOrderStatusPage
} from './utils';
import errorHandler from './errorHandler';
import { isDevMode } from './devMode';
import productPage from './pages/productPage';
import cartPage from './pages/cartPage';
import cartPageInjector from './pages/cartPageInjector/index';
import productPageInjector from './pages/productPageInjector';
import { popupVariantSelectionHandler } from './template/components/popUp/variantSelection';
import injectThemeEditorStyles from './render/common/injectThemeEditorStyles';
import queryDatabase, { fetchProduct } from './api/database';
import orderStatusPage from './pages/orderStatusPage';

let TEMP_FIREBASE_CACHE = null;

export default function app() {
  // Init important stuff.
  String.prototype.insertAt = function(index, string) {
    return `${this.substr(0, index)}${string}${this.substr(index)}`;
  };
  String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };

  getPredsAndAddOns(function(response) {
    const options = response.options;
    const addOnsDb = response.addons;
    const popups = response.popups;
    const discount_offer = response.discount_offer;
    const discount_offer_collection = response.discount_offer_collection;
    const discount_offer_catalog = response.discount_offer_catalog;

    if (!addOnsDb || (options.disableApp && !isDevMode())) return;

    // Hide addons visible if hidden settings overrides.
    if (window.upsellHiddenPrefs && window.upsellHiddenPrefs.enabled) {
      return;
    }

    // Define variables if not founded
    if (!window.upsell) {
      window.upsell = {};
    }

    // Store all needed data
    setAppProps({
      options,
      addOnsDb,
      popups,
      discount_offer,
      discount_offer_collection,
      discount_offer_catalog
    });

    if (isProductPage()) {
      productPage();
      productPageInjector();
    } else if (isCartPage()) {
      cartPage();
      cartPageInjector();
    } else if (isOrderStatusPage()) {
      // orderStatusPage();
    }

    popupVariantSelectionHandler();
    injectThemeEditorStyles();
  });
}

function getPredsAndAddOns(callBack) {
  if (
    !isDevMode() &&
    loadFromCache(cached => {
      if (isProductPage()) {
        fetchProduct(() => {
          callBack(cached);
        });
      } else {
        callBack(cached);
      }
    })
  ) {
    return;
  }

  // load from cache
  if (isTempCache()) {
    loadFromTempCache(callBack);
    return;
  }

  loadScript(
    'https://www.gstatic.com/firebasejs/6.6.1/firebase-app.js',
    function() {
      loadScript(
        'https://www.gstatic.com/firebasejs/6.6.1/firebase-database.js',
        function() {
          //Some Apps are really naugthy and have process as a globa var
          //we are just going to rest it
          window.process = undefined;

          queryDatabase(function(snapshot) {
            try {
              const rootVal = snapshot;
              if (rootVal) {
                cache.set({
                  options: rootVal.prefs,
                  addOns: rootVal.addons || {},
                  popups: rootVal.popups || {},
                  discount_offer: rootVal.discount_offer || {},
                  discount_offer_collection:
                    rootVal.discount_offer_collection || {},
                  discount_offer_catalog: rootVal.discount_offer_catalog || {}
                });

                const initOptions = {
                  ...getDefaultOpitons(),
                  ...rootVal.prefs
                };
                const data = {
                  options: initOptions,
                  addons: rootVal.addons || {},
                  popups: rootVal.popups || {},
                  discount_offer: rootVal.discount_offer || {},
                  discount_offer_collection:
                    rootVal.discount_offer_collection || {},
                  discount_offer_catalog: rootVal.discount_offer_catalog || {}
                };
                saveTempCache(data);
                callBack(data);
              }
            } catch (err) {
              errorHandler(err);
            }
          });
        }
      );
    }
  );
}

function loadFromCache(callBack) {
  const cached = cache.get();
  if (
    cached &&
    cached.options &&
    cached.addons &&
    cached.popups &&
    cached.discount_offer
  ) {
    callBack(cached);
    return true;
  }
  return false;
}

function getDefaultOpitons() {
  return {
    addOnType: 'button',
    showDetailsLink: false,
    showPlus: true,
    bold: true,
    showBorder: false,
    textColor: '000000',
    displayOnDisabledCartButton: true,
    popUpAddOnType: 'button',
    popUpLinkTitle: true,
    discountOfferLinkTitle: true
  };
}

function isTempCache() {
  return TEMP_FIREBASE_CACHE;
}

function saveTempCache(data) {
  TEMP_FIREBASE_CACHE = data;
}

function loadFromTempCache(callback) {
  if (TEMP_FIREBASE_CACHE) {
    callback(TEMP_FIREBASE_CACHE);
  }
}
