import { STYLE_TOKENS, styles } from '../../templates';
import { BOX_TYPE, PAGE } from '../../domain';
import { getButtonPadding, getImageDimension } from './utils';

function injectStyles(options, page) {
  let css = styles();
  const boxWidth =
    page === PAGE.Product
      ? options.productBoxWidth || ''
      : options.cartBoxWidth || '400px';
  const boxHeight =
    page === PAGE.Product
      ? options.productBoxHeight || ''
      : options.cartBoxHeight || '';
  const containerWidth =
    page === PAGE.Product
      ? options.productContainerWidth || ''
      : options.cartContainerWidth || '';
  const containerHeight =
    page === PAGE.Product
      ? options.productContainerHeight || ''
      : options.cartContainerHeight || '';
  let cartButtonWidth = options.cartButtonWidth;
  const boxWidthRegEx = new RegExp(STYLE_TOKENS.addOnBoxWidth, 'g');
  const boxHeightRegEx = new RegExp(STYLE_TOKENS.addOnBoxHeight, 'g');
  if (!cartButtonWidth) {
    if (
      page === PAGE.Product &&
      (options.productBoxType === BOX_TYPE.ImageTop ||
        options.productBoxType === BOX_TYPE.ImageLeft)
    ) {
      cartButtonWidth = '100%';
    } else if (
      page === PAGE.Cart &&
      (options.cartBoxType === BOX_TYPE.ImageTop ||
        options.cartBoxType === BOX_TYPE.ImageLeft)
    ) {
      cartButtonWidth = '100%';
    }
  } else {
    cartButtonWidth = `${cartButtonWidth}px`;
  }

  css = css
    .replace(
      STYLE_TOKENS.fontColor,
      options.textColor
        ? `color: ${
            options.textColor.indexOf('#') >= 0
              ? `${options.textColor}!important`
              : '#' + options.textColor
          };`
        : ''
    )
    .replace(
      STYLE_TOKENS.fontSize,
      options.titleSize ? `font-size:${options.titleSize}px!important;` : ''
    )
    .replace(
      STYLE_TOKENS.headerFontSize,
      options.headerFontSize
        ? `font-size: ${options.headerFontSize}px!important;`
        : ''
    )
    .replace(
      STYLE_TOKENS.headerColor,
      options.headerColor ? `color: ${options.headerColor}!important;` : ''
    )
    .replace(
      STYLE_TOKENS.borderWidth,
      options.showBorder ? `${options.borderWidth || 1}px` : '0px'
    )
    .replace(STYLE_TOKENS.borderColor, options.borderColor || '#95a5a6')
    .replace(STYLE_TOKENS.fontWeight, options.bold ? '700' : '400')
    .replace(
      STYLE_TOKENS.imageHeight,
      getImageDimension(
        'height',
        options.imageHeight,
        options.setInitialImageHeight,
        50
      )
    )
    .replace(
      STYLE_TOKENS.imageWidth,
      getImageDimension(
        'width',
        options.imageWidth,
        options.setInitialImageWidth
      )
    )
    .replace(STYLE_TOKENS.buttonHeight, options.cartButtonHeight || 35)
    .replace(
      STYLE_TOKENS.buttonWidth,
      cartButtonWidth ? `width:${cartButtonWidth}!important;` : ''
    )
    .replace(
      STYLE_TOKENS.buttonFontSize,
      options.cartButtonFontSize
        ? `${options.cartButtonFontSize}px!important;`
        : '0.75em'
    )
    .replace(
      STYLE_TOKENS.buttonPaddingLeft,
      getButtonPadding(options.cartButtonPaddingLeft, 7)
    )
    .replace(
      STYLE_TOKENS.buttonPaddingTop,
      getButtonPadding(options.cartButtonPaddingTop, 1)
    )
    .replace(
      STYLE_TOKENS.buttonPaddingRight,
      getButtonPadding(options.cartButtonPaddingRight, 7)
    )
    .replace(
      STYLE_TOKENS.buttonPaddingBottom,
      getButtonPadding(options.cartButtonPaddingBottom, 1)
    )
    .replace(boxWidthRegEx, boxWidth ? `width:${boxWidth};` : '')
    .replace(boxHeightRegEx, boxHeight ? `height:${boxHeight};` : '')
    .replace(STYLE_TOKENS.flexGrow, boxWidth ? '' : 'width: 100%;')
    .replace(STYLE_TOKENS.flexWrap, options.flexNoWrap ? 'nowrap' : 'wrap')
    .replace(
      STYLE_TOKENS.containerWidth,
      containerWidth ? `width:${containerWidth}!important;` : ''
    )
    .replace(
      STYLE_TOKENS.containerHeight,
      containerHeight ? `height:${containerHeight}!important;` : ''
    )
    .replace(
      STYLE_TOKENS.descriptionFontColor,
      options.descFontColor ? `color:${options.descFontColor};` : ''
    )
    .replace(
      STYLE_TOKENS.descriptionFontSize,
      options.descFontSize ? `font-size:${options.descFontSize}px;` : ''
    )
    .replace(
      STYLE_TOKENS.customCss,
      options.customCSS ? decodeURIComponent(options.customCSS) : ''
    )
    .replace(
      STYLE_TOKENS.customPopupCss,
      options.customPopupCSS ? decodeURIComponent(options.customPopupCSS) : ''
    );

  document.querySelector('head').insertAdjacentHTML('beforeend', css);
}

export default injectStyles;
