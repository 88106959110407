import { PAGE } from './domain';
import { getAddOns } from './addOnsUtil';
export default function getCollectionAddOns(
  page,
  productId,
  addOnsDb,
  addOnsTracker,
  addOnsToIgnore,
  groupVariantsTracker
) {
  if (addOnsDb.collection) {
    const collectionIds = getCollectionIds(page, productId);
    const collectionAddOns = [];
    if (collectionIds && collectionIds.length > 0) {
      for (let i = 0; i < collectionIds.length; i++) {
        const addOns = getAddOns(
          function() {
            return addOnsDb.collection[collectionIds[i].toString()];
          },
          addOnsDb,
          addOnsTracker,
          addOnsToIgnore,
          groupVariantsTracker
        );
        collectionAddOns.push.apply(collectionAddOns, addOns);
      }
      return collectionAddOns;
    }
  }
  return [];
}

export function getCollectionIds(page, productId) {
  if (page === PAGE.Product) {
    if (window.upsell && window.upsell.product) {
      return window.upsell.product.collections;
    }
  } else {
    if (
      window.upsell &&
      window.upsell.cart &&
      window.upsell.cart.products &&
      window.upsell.cart.products[productId.toString()]
    ) {
      return window.upsell.cart.products[productId.toString()].collections;
    }
  }
}
