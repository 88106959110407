import { getAppProps, getPriceDetails } from '../../utils';
import { ADDON_TYPE, PAGE } from '../../domain';
import { button, checkbox, qtyBox, qtyDropdown } from '../../templates';
import CssClasses from '../../template/common/cssClasses';

const KNOWN_CONTAINER_ID = 'upsell-container';
export const APP_BLOCK_CONTAINER = 'upsell-theme-app-block-custom';
export const CLICK_HOOK_ATTR_ADD_TO_CART = 'upsell-click-hooked-trigger';

export function getImageDimension(cssProperty, dimension, reset, defaultVal) {
  if (reset) {
    return `${cssProperty}:initial!important;`;
  }

  if (dimension) {
    return `${cssProperty}:${dimension}px!important;`;
  }

  return defaultVal ? `${cssProperty}:${defaultVal}px;` : '';
}

export function getButtonPadding(paddingValue, defaultValue) {
  if (paddingValue === 0) {
    return 0;
  }
  return paddingValue || defaultValue || 1;
}

export function getImageUrl(options, addOnDetails, variantIndex) {
  const image =
    addOnDetails.variantOptions && addOnDetails.variantOptions.length > 0
      ? options.useThumbnailImage
        ? addOnDetails.variantOptions[variantIndex].originalSmallImage
        : addOnDetails.variantOptions[variantIndex].image
      : options.useThumbnailImage
      ? addOnDetails.originalSmallImage
      : addOnDetails.image;
  return image || '';
}

export function getPrices(options, addOnDetails, variantIndex) {
  const hasVariantOptions =
    addOnDetails.variantOptions && addOnDetails.variantOptions.length > 0;
  const compareAtPrice = hasVariantOptions
    ? addOnDetails.variantOptions[variantIndex].compareAtPrice
    : addOnDetails.compareAtPrice;
  const price = hasVariantOptions
    ? addOnDetails.variantOptions[variantIndex].price
    : addOnDetails.price;

  return getPriceDetails(options, price, compareAtPrice);
}

export function getUrl(addOnDetails, variantIndex) {
  const url =
    addOnDetails.variantOptions && addOnDetails.variantOptions.length > 0
      ? addOnDetails.variantOptions[variantIndex].url
      : addOnDetails.url;
  return url;
}

export function isVisible(e) {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

export function getQtyDropdownOptions(options) {
  if (options.quantityDropdownOptions) {
    return options.quantityDropdownOptions.split(',');
  }
  return [];
}

export function isValidSelector(str) {
  return typeof str === 'string' && str.length > 0;
}

export function findForm(action) {
  const forms = [];
  const allForms = document.querySelectorAll('form');
  for (let i = 0; i < allForms.length; i++) {
    const form = allForms[i];
    if (form.action && form.action.indexOf(action) >= 0 && isVisible(form)) {
      let len = form.getElementsByTagName('*').length;

      // Increase len score if we have quantity field
      const qtyLen = form.querySelectorAll('input[name="quantity"]').length;
      if (qtyLen > 0) {
        len = len + 20 * qtyLen;
      }

      forms.push({
        element: form,
        len
      });
    }
  }

  let form = null;
  let max = 0;

  for (const iter of forms) {
    if (iter.len > max) {
      max = iter.len;
      form = iter.element;
    }
  }

  return form;
}

function findButtonByPage(form, page) {
  let btn = null;
  if (page === PAGE.Product) {
    btn =
      form.querySelector('button[type="submit"]') ||
      form.querySelector('input[type="submit"]') ||
      form.querySelector('.btn-addtocart') ||
      form.querySelector('button[name="add"]') ||
      form.querySelector('a.addtocart') ||
      document.querySelector('.product-form__submit');
  }

  if (page === PAGE.Cart) {
    btn =
      form.querySelector('button[name="checkout"]') ||
      form.querySelector('input[name="checkout"]') ||
      document.querySelector('.cart__checkout-button');
  }
  return btn;
}

export function getSubmitButton(options, form, page) {
  let btn = null;

  if (page === PAGE.Product) {
    // Use from options
    if (isValidSelector(options.addToCartButtonSelector)) {
      btn = document.querySelector(options.addToCartButtonSelector);
    }

    // Find in form
    if (!btn) {
      btn = findButtonByPage(form, PAGE.Product);
    }
  } else if (page === PAGE.Cart) {
    if (isValidSelector(options.checkoutButtonSelector)) {
      btn = document.querySelector(options.checkoutButtonSelector);
    }

    // Find in form
    if (!btn) {
      btn = findButtonByPage(form, PAGE.Cart);
    }
  } else if (page === PAGE.OrderStatus) {
    if (isValidSelector(options.checkoutOrderStatusButtonSelector)) {
      btn = document.querySelector(options.checkoutOrderStatusButtonSelector);
    }

    // Find in form
    if (!btn) {
      btn = document.querySelector('a[data-osp-continue-button]');
    }
  }

  return btn;
}

export function getBuyItNowButton(form = document) {
  const { options } = getAppProps();

  let btn = null;
  if (options.buyItNowButtonSelector) {
    btn = document.querySelector(options.buyItNowButtonSelector);
  }

  if (!btn) {
    return (
      form.querySelector('[data-testid="Checkout-button"]') ||
      form.querySelector('.shopify-payment-button__button')
    );
  }
}

function getValidTemplate(template) {
  if (typeof template !== 'string') {
    return null;
  }

  const str = decodeURIComponent(template);
  if (str) {
    // Don't use empty template
    if (str.trim() === '') {
      return null;
    }

    return str;
  }

  return null;
}

function getAddToCartInputTemplate(options, page) {
  const template =
    page === PAGE.Product
      ? options.productInputTemplate
      : options.cartInputTemplate;
  return getValidTemplate(template);
}

export function getAddOnInput(
  options,
  page,
  addToCartButton,
  isGroupedVariant,
  productHandle,
  addOnDetails
) {
  const template = getAddToCartInputTemplate(options, page);
  let buttonQtyType = null;
  if (page === PAGE.Product) {
    if (options.quantitySelect) {
      return qtyBox({ isGroupedVariant, productHandle, addOnDetails });
    }

    if (options.addOnType === ADDON_TYPE.Dropdown) {
      const qtyOptions = getQtyDropdownOptions(options);
      if (qtyOptions.length > 0) {
        return qtyDropdown({
          isGroupedVariant,
          productHandle,
          options: qtyOptions,
          addOnDetails
        });
      }
    }

    if (options.addOnType === ADDON_TYPE.Checkbox) {
      return checkbox({
        isGroupedVariant,
        productHandle,
        template,
        addOnDetails
      });
    }
    buttonQtyType =
      options.addOnType === ADDON_TYPE.ButtonQtyBox ? ADDON_TYPE.QtyBox : null;
  } else {
    buttonQtyType = options.cartQtyType;
  }

  return button({
    original: addToCartButton,
    isGroupedVariant,
    productHandle,
    qtyType: buttonQtyType,
    addOnDetails
  });
}

export function getForm(page, options) {
  let cartForm;
  if (page === PAGE.Product) {
    // Use from options
    if (isValidSelector(options.productPageFormSelector)) {
      cartForm = document.querySelector(options.productPageFormSelector);
    }
    // Find if is not set
    if (!cartForm) {
      cartForm = findForm('/cart/add');
    }
  }

  if (page === PAGE.Cart) {
    // Use from options
    if (isValidSelector(options.cartPageFormSelector)) {
      cartForm = document.querySelector(options.cartPageFormSelector);
    }
    // Find if is not set
    if (!cartForm) {
      cartForm = findForm('/cart');
    }
    if (!cartForm) {
      cartForm = findForm('/checkout');
    }
  }

  if (page === PAGE.OrderStatus) {
    // Use from options
    if (isValidSelector(options.orderStatusPageFormSelector)) {
      cartForm = document.querySelector(options.orderStatusPageFormSelector);
    }

    // Find if is not set
    if (!cartForm) {
      cartForm = document.querySelector('.step__sections');
    }
  }

  return cartForm;
}

export function getAddOnBoxTemplate(options, page) {
  const template =
    page === PAGE.Product
      ? options.productAddOnTemplate
      : options.cartAddOnTemplate;
  return getValidTemplate(template);
}

export function tryRenderInKnownContainer(loading) {
  const knownContainer = document.querySelector(`#${KNOWN_CONTAINER_ID}`);
  if (knownContainer) {
    knownContainer.insertAdjacentHTML('afterbegin', loading);
    return true;
  }
  const appBlocks = document.querySelectorAll(`.${APP_BLOCK_CONTAINER}`);
  if (appBlocks.length > 0) {
    appBlocks.forEach(v => {
      v.insertAdjacentHTML('afterbegin', loading);
    });
    return true;
  }
  return false;
}

export function removeUpsell() {
  const containers = document.querySelectorAll(`.${CssClasses.container}`);
  containers.forEach(container => {
    container.remove();
  });
}

export function getCartDrawersForm() {
  let form = null;

  const { options } = getAppProps();
  if (isValidSelector(options.cartDrawerSelectorForm)) {
    form = document.querySelector(options.cartDrawerSelectorForm);
  }

  if (!form) {
    const forms = [];
    const allForms = document.querySelectorAll('form');
    for (let i = 0; i < allForms.length; i++) {
      const form = allForms[i];
      if (
        form.action &&
        form.method === 'POST' &&
        (form.action.indexOf('/cart') >= 0 || form.action.indexOf('/checkout'))
      ) {
        let len = 1;

        // Increase score
        if (form.id === 'cart') {
          len = len * 10000;
        }

        if (form.className.include('cart')) {
          len = len * 100;
        }

        if (form.className.include('drawer')) {
          len = len * 10;
        }

        if (form.querySelector('button[type="submit"]')) {
          len = len * 10;
        }

        if (form.querySelector('input[type="submit"]')) {
          len = len * 10;
        }

        forms.push({
          element: form,
          len
        });
      }
    }

    let max = 0;

    for (const iter of forms) {
      if (iter.len > max) {
        max = iter.len;
        form = iter.element;
      }
    }
  }

  return form;
}
