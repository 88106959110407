import Wrapper from '../../template/components/popUp/wrapper';
import { getPopUpData } from './utils';
import { initPopUp } from '../../template/components/popUp';
import { callEmptyNextStep, callNextStep } from './common';
import hookInstall from '../../utils/hookInstall';
import { getBuyItNowButton } from '../../render/common/utils';
import { isMandatoryExists, showMandatoryInfo } from '../../template/components/mandatory';

const attributeSetting = 'upsell-hooked-buy-it-now';
// Symbol
const symbol = Symbol.for(attributeSetting);

function addHookBuyItNow() {
  const btnReplace = getBuyItNowButton();

  if (btnReplace){
    const symbols = Object.getOwnPropertySymbols(btnReplace);
    const [sym] = symbols;
    if (btnReplace[sym] === true){
      return false;
    }
  }

  if (!btnReplace) {
    return false;
  }

  // Don't hook if it's disabled
  if (btnReplace.disabled) {
    return false;
  }

  // Replace button by ours (prevent default clicks).
  jUpsell(btnReplace).replaceWith(jUpsell(btnReplace).clone());

  // Hook buy it now
  const btn = getBuyItNowButton();
  if (!btn) {
    return false;
  }

  btn[symbol] = true;

  const wrap = new Wrapper({
    useCheckout: true
  });
  wrap.onAddToCart = () => {
    callNextStep();
  };
  wrap.onEmpty = () => {
    callEmptyNextStep();
  };
  wrap.onClose = () => {
    callEmptyNextStep();
  };

  btn.addEventListener('click', e => {
    // Disable addToCart click if we don't add some addons
    if (isMandatoryExists()) {
      e.preventDefault();
      e.stopImmediatePropagation();
      showMandatoryInfo();
      return;
    }

    e.preventDefault();

    const variants = getPopUpData();

    if (variants !== null) {
      initPopUp(wrap);
    } else {
      // Add main product
      // Call discount
      callEmptyNextStep();
    }
  });

  return true;
}

function onDomChange() {
  const el = document.querySelector('[data-shopify="payment-button"]');
  if (el) {
    const MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;

    let enabledCalls = true;

    var observer = new MutationObserver(function(mutations) {
      if (!enabledCalls) {
        return;
      }

      if (mutations.length > 0) {
        enabledCalls = false;
        addHookBuyItNow();
        enabledCalls = true;
      }
    });

    observer.observe(el, {
      subtree: true,
      attributes: true
    });
  }
}

async function loadHook() {
  await hookInstall(addHookBuyItNow, undefined, 10);

  onDomChange();
}

export function hookBuyItNow() {
  if (document.readyState === 'complete') {
    loadHook();
  } else {
    // Load only after loading all
    if (window.attachEvent) {
      window.attachEvent('onload', loadHook);
    } else {
      window.addEventListener('load', loadHook, false);
    }
  }

  return true;
}
