import CssClasses from './cssClasses';
import STYLE_TOKENS from './styleTokens';
import Element_Ids from './elementIds';

function styles() {
  return `<style type="text/css"> 
        .${CssClasses.container} {
                margin-top:30px;
                margin-bottom:10px;
                height: auto;
                border: 0px;
        }
        .${CssClasses.invisible}{visibility:hidden;max-height:0px;} 
        .${CssClasses.hidden}{display:none;}
        .${CssClasses.inline}{display:inline;}
        .${CssClasses.header} {
            ${STYLE_TOKENS.headerFontSize}
            ${STYLE_TOKENS.headerColor}
        }
        .${CssClasses.description} {
          ${STYLE_TOKENS.descriptionFontSize}
          ${STYLE_TOKENS.descriptionFontColor}
          text-align:initial;
        }
        .${CssClasses.addonBox} {
          border:${STYLE_TOKENS.borderWidth} solid ${STYLE_TOKENS.borderColor};
          border-radius:4px;         
          padding:6px;   
          box-sizing: border-box; 
          ${STYLE_TOKENS.addOnBoxWidth}
          ${STYLE_TOKENS.addOnBoxHeight}
        }

        .${CssClasses.defaultLayout}  .${CssClasses.addonBox} {
          margin-bottom:12px;
        }
        .${CssClasses.defaultAddOnBox} {
          display:flex;      
        }

        .${CssClasses.qtyWrapper} {
           display:flex;      
           justify-content: center;
           align-items: center;
           margin-left: 2px;
        }
        
        .${CssClasses.qtyWrapperDrop} {
           display:flex;      
           justify-content: center;
           align-items: center;
           margin-left: 2px;
        }
        
        .${CssClasses.qtyDropdown} {
            min-height: 30px !important;
            width: 50px;
        }
       
        .${CssClasses.placeholder} .${CssClasses.col2},
        .${CssClasses.defaultAddOnBox} .${CssClasses.col2}{
            margin-left:4px;
        }

        .${CssClasses.defaultAddOnBox}  .${CssClasses.col3}{
           margin-left:auto;
           text-align:center;
           padding-left:4px;
        }
        
        .${CssClasses.flexLayout}{
            display:flex;
            flex-wrap:${STYLE_TOKENS.flexWrap};
        }
        
        .${CssClasses.flexLayout} .${CssClasses.addonBox} {
            ${STYLE_TOKENS.flexGrow}
            margin-right:8px;
            margin-bottom:10px;
         }
         .${CssClasses.imageTop} .${CssClasses.col2} {
            text-align:center; 
         }
         
         .${CssClasses.buttonQty} {margin-bottom:5px;}
         .${CssClasses.hasButtonQtyInput} {width:100%;}
        
        .${CssClasses.imageLeft} .${CssClasses.upsellPrice},
        .${CssClasses.imageTop} .${CssClasses.upsellPrice} {
            text-align:center;
            width:100%;
         }
         .${CssClasses.imageLeft} label.${CssClasses.upsellPrice},
         .${CssClasses.imageTop} label.${CssClasses.upsellPrice} {
            display:block;
         }
         .${CssClasses.imageTop} .${CssClasses.col1}{
            text-align:center;          
         }

         .${CssClasses.imageLeft} .${CssClasses.cartButton},
         .${CssClasses.imageTop} .${CssClasses.cartButton} {
            display:block !important;
         }

         .${CssClasses.imageLeft} .${CssClasses.col2}{
           display:flex;
         }
         .${CssClasses.imageLeft} .${CssClasses.col3} {
            margin-left:8px;
         }

         .Brooklyn .${CssClasses.sliderLayout}{
            height:250px;
            width:400px;
         }
         
         /* modify select */
         .${CssClasses.variantDropdown} > select {
            min-height: 36px;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
         }
         
         /* on top */
         .${CssClasses.imageTop} .${CssClasses.col3} > div {
            flex-wrap: wrap;
            justify-content: center;
         }
         .${CssClasses.imageTop} .${CssClasses.col3} > div > label {
            display: block !important;
         }
         .${CssClasses.imageTop} .${CssClasses.col3} > div 
         > label > .${CssClasses.customBreakLineDawn},
         .${CssClasses.imageTop} .${CssClasses.col3} > div 
         > label > .${CssClasses.customBreakLine} {
            display: inline;
         }
         .${CssClasses.imageTop} .${CssClasses.col3} > div 
         > label > .${CssClasses.customBreakLineDawn} + .${CssClasses.compareAtPrice},
         .${CssClasses.imageTop} .${CssClasses.col3} > div 
         > label > .${CssClasses.customBreakLine} + .${CssClasses.compareAtPrice} {
            display: inline;
         }
         .${CssClasses.imageTop} + .${CssClasses.description} {
            text-align: center;
         }
         .${CssClasses.imageTop} .${CssClasses.variantDropdown} {
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 10px;
         }
         
         /* on left */
         .${CssClasses.imageLeft} .${CssClasses.col3} {
            flex-grow: 1;
         }
         .${CssClasses.imageLeft} .${CssClasses.col3} > div > .${CssClasses.priceTargetElement}  {
            text-align: right;
         }
         .${CssClasses.imageLeft} .${CssClasses.variantDropdown} {
            justify-content: end;
            margin-bottom: 10px;
         }
         
         .${CssClasses.addOnContainer}{
            ${STYLE_TOKENS.containerWidth}
            ${STYLE_TOKENS.containerHeight}
         }

         .${CssClasses.sliderLayout} .${CssClasses.addonBox}{
            margin-right:8px;
         }
         
         .${CssClasses.sliderLayout} .${CssClasses.imageTop} .${CssClasses.col3} {
            position:absolute;
            bottom:0px;
            left:0;
            right:0;
            width:100%;
            padding-left:5px;
            padding-right:3px;
         }
         .${CssClasses.addOnContainer} .product-single__title {
            display: inline-block;
            line-height: 15px;
         }
        .${CssClasses.addonBox} img{
          ${STYLE_TOKENS.imageHeight}
          ${STYLE_TOKENS.imageWidth}
        }
        .upsell-addon-title{
          font-weight:${STYLE_TOKENS.fontWeight};
          ${STYLE_TOKENS.fontColor}
          ${STYLE_TOKENS.fontSize}
        }
        .upsell-qty-label{
          margin-right:1px;
        }
        .upsell-qty-label,
        .${CssClasses.upsellPrice},
        .upsell-addon-details{
          font-size:0.9375em;
          font-weight:normal;
        }      
        .${CssClasses.upsellPrice} {
          white-space: nowrap;
        }
        
        .${CssClasses.upsellPrice} input[type="checkbox"]{
           position:relative;
           top:1px;
           min-height:initial;
        }
        label.${CssClasses.upsellPrice}{
          white-space:nowrap;
        }

        .${CssClasses.cartButton}{
          min-width: 100px !important;
          height: ${STYLE_TOKENS.buttonHeight}px!important;
          ${STYLE_TOKENS.buttonWidth}
          padding-left:${STYLE_TOKENS.buttonPaddingLeft}px!important;
          padding-top:${STYLE_TOKENS.buttonPaddingTop}px!important;
          padding-right:${STYLE_TOKENS.buttonPaddingRight}px!important;
          padding-bottom:${STYLE_TOKENS.buttonPaddingBottom}px!important;
          font-size:${STYLE_TOKENS.buttonFontSize}!important;
          margin-bottom: 0px !important;
        }
        .upsell-cartpage {            
            display:flex;
            flex-wrap: wrap;
            justify-content: center;            
        }
       
        .upsell-cartpage .${CssClasses.addonBox}{
          margin-left: 12px;
          ${STYLE_TOKENS.addOnBoxWidth}
          ${STYLE_TOKENS.addOnBoxHeight}      
        }

        .${CssClasses.cartPageContainer} h4 {
          text-align: center;
        }

        .${CssClasses.cartPageContainer} .${CssClasses.placeholder},
        .${CssClasses.cartPageContainer} .${CssClasses.addOnContainer} {
          margin:auto;
        }
        
        @keyframes placeHolderShimmer{
          0%{
              background-position: -468px 0
          }
          100%{
              background-position: 468px 0
          }
        }
      
        .${CssClasses.animatedBackground} {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
            background-size: 800px 104px;
            height: 96px;
            position: relative;
        }
        .${CssClasses.addonBox}.${CssClasses.placeholder} {
          height:80px;
          display:flex;
          width:400px;
          flex-grow:initial;
        }

        .${CssClasses.placeholder} .${CssClasses.col1}{
            height:60px;
            width:100px;
        }
        
        .${CssClasses.col2} .${CssClasses.placeholderLine1} {
          height:10px;
          width:200px;
        }

        .${CssClasses.col2} .${CssClasses.placeholderLine2} {
          height:10px;
          width:100px;
          margin-top:5px;
        }

        .Brooklyn .${CssClasses.title}{
          font-size:1em!important
        }
        .${CssClasses.compareAtPrice} {
          margin-left: auto;
          text-decoration: line-through;
          display:inline-block;
          font-size: 0.92em;
        }
        
        .${CssClasses.qtyBox} {
          display:flex; 
        }

        .${CssClasses.imageLeft} .${CssClasses.qtyBox},
        .${CssClasses.imageTop} .${CssClasses.qtyBox} {
          display:block;        
        }
        
        .${CssClasses.upsellPrice}{
           margin-right:3px;
        }
        
        .${CssClasses.qtyBoxInput} {
          width:50px;          
        }
       
        .${CssClasses.qtyBoxInput} input {
           width:50px;  /* FIX FOR DAWN */ 
           padding:5px 0px 5px 5px;
           height:initial;
           min-height:initial;
        }
        .${CssClasses.variantDropdown} {
          display:flex;
          align-items: baseline;
        }
        .${CssClasses.imageTop} .${CssClasses.variantDropdown} {
          flex-wrap:wrap;
        }
        .${CssClasses.variantDropdown} label {
          padding-right:3px;
        }
        @media only screen and (max-width: 479px) {
          .Venture .${CssClasses.defaultAddOnBox} .${CssClasses.qtyWrapper},
          .Debut .${CssClasses.defaultAddOnBox} .${CssClasses.qtyWrapper} {
             flex-wrap:wrap;
          }    
          .Venture .${CssClasses.defaultAddOnBox} .${CssClasses.qtyWrapper} div,
          .Debut .${CssClasses.defaultAddOnBox} .${CssClasses.qtyWrapper} div {
            flex:1;
          }

          .${CssClasses.flexLayout} {
            flex-wrap:wrap;
          }
        }
        .${CssClasses.boldDiscount} {
            font-weight: bold;
        }
        
        .${CssClasses.hasCompareAtPrice} > b {
            font-weight: bold !important;
        }
        
        .${CssClasses.cartDiscountPriceWrap} {
            display: flex;
            flex-direction: column;
        }
        
        /* break line*/
        .${CssClasses.customBreakLine} {
        }
        .${CssClasses.customBreakLine} + .${CssClasses.compareAtPrice} {
            display: block;
        }
        .${CssClasses.customBreakLineDawn} {
            margin: -4px 0;
            content: "";
        }
        .${CssClasses.customBreakLineDawn} + .${CssClasses.compareAtPrice} {
            display: block;
            margin-bottom: -10px;
        }
        
        /* override input min-height */
        .${Element_Ids.uiBlockWrapper} > input {
            min-height: 0px !important;
            height: 21px !important;
        }
        
        /* link */
        .${CssClasses.link},
        .${CssClasses.link} :hover, 
        .${CssClasses.link} :focus,
        .${CssClasses.link} :active,
        .${CssClasses.link} a,
        .${CssClasses.link} a:hover, 
        .${CssClasses.link} a:focus,
        .${CssClasses.link} a:active {
            text-decoration: none;
            color: inherit;
        }
        
        /* remove theme focus style */
        .${CssClasses.qtyBoxInput} > select:focus-visible,
        .${CssClasses.qtyBoxInput} > select:focus,
        .${CssClasses.variantDropdown} > select:focus-visible,
        .${CssClasses.variantDropdown} > select:focus {
            outline: 0 !important;
            box-shadow: none !important;
        }
        
        /* bundles */
        .${CssClasses.variantBundleDropdown} {
            display: block;
        }
        
        .${CssClasses.variantBundleDropdown} > select {
            width: 90%;
        }
          
        ${STYLE_TOKENS.customCss}
        ${STYLE_TOKENS.customPopupCss}
    </style>`;
}

export default styles;
