const UPSELL_DEV_MODE = 'upsell-dev-mode';

export function isDevMode() {
  try {
    if (createDevSession()) {
      document.cookie = 'upsell-dev-mode=true';
      return true;
    }
    return document.cookie.indexOf(UPSELL_DEV_MODE) >= 0;
  } catch (e) {
    return false;
  }
}

function createDevSession() {
  return window.location.href.toLocaleLowerCase().indexOf(UPSELL_DEV_MODE) >= 0;
}
